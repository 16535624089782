import { useCallback, useEffect, useState } from "react";

import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

import { Input } from "../../components/Contract/Input";
import { MonthSelect } from "../../components/Contract/MonthSelect";
import { StyledSelect } from "../../components/Contract/StyledSelect";
import { YearSelect } from "../../components/Contract/YearSelect";
import { ClientInformationModal } from "../../components/Modals/ClientInformationModal";

import api from "../../services/api";
import { encodedParams } from "../../utils/encodedParams";

import { StaffDTO } from "../../dtos/StaffDTO";

import {
  Container,
  Header,
  Title,
  FilterBox,
  TableFilter,
  FilterDetail,
  Summary,
  Table,
  TableHead,
  TableBody,
  TableRow,
  BodyRow,
  TableHeadColumn,
  TableBodyColumn,
} from "./styles";
import { format } from "date-fns";
import { SortDTO } from "../../dtos/SortDTO";
import { ContractDTO } from "../../dtos/ContractDTO";
import { SortIcon } from "../../components/SortIcon";
import { handleSetSort } from "../../utils/handleSetSort";
import { OpenModalDTO } from "../../dtos/OpenModalDTO";

interface OptionProps {
  label: string;
  value: string;
}

interface FormData {
  fid: number; // 処理ID
  ukey: string; // ユーザーKEY
  y?: number; //	年
  m?: number; //	月
  csno?: string; //	顧客No
  csnm?: string; //	顧客名
  addr?: string; //	住所
  uid?: number; //	社員ID
  sort?: number; //	ソート順
  desc?: number; //	ソート降順有無
}

interface TotalFormData {
  fid: number; // 処理ID
  ukey: string; // ユーザーKEY
  y?: number; //	年
  m?: number; //	月
}

export function Contract() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMpid, setModalMpid] = useState("");
  const [modalCsid, setModalCsid] = useState("");

  const [sort, setSort] = useState<SortDTO>({
    desc: 1,
    sort: 0,
  });

  const [year, setYear] = useState<Date>(new Date());
  const [month, setMonth] = useState<number | undefined>();

  const [filterStaff, setFilterStaff] = useState<string | undefined>();
  const [staffOptions, setStaffOptions] = useState<OptionProps[]>(
    [] as OptionProps[]
  );

  const [filterClientNo, setFilterClientNo] = useState<string | undefined>(); // フィルター顧客No.
  const [filterClientName, setFilterClientName] = useState<
    string | undefined
  >(); // フィルター顧客名
  const [filterClientAddress, setFilterClientAddress] = useState<
    string | undefined
  >(); // フィルター住所

  const [contractList, setContractList] = useState<ContractDTO[]>();
  const [total, setTotal] = useState<number>();

  const load = useCallback(async () => {
    try {
      let data: FormData = {
        ukey: "trial",
        fid: 31,
        y: year.getFullYear(),
        desc: sort.desc,
        sort: sort.sort,
      };

      let totalData: TotalFormData = {
        fid: 29,
        ukey: "trial",
        y: year.getFullYear(),
      };

      // フィルター社員で選択されていればPOST通信と一緒に送信
      if (filterStaff) {
        data = {
          ...data,
          uid: Number(filterStaff),
        };
      }
      // フィルター顧客Noが入力されていればPOST通信と一緒に送信
      if (filterClientNo) {
        data = {
          ...data,
          csno: filterClientNo,
        };
      }
      // フィルター顧客名が入力されていればPOST通信と一緒に送信
      if (filterClientName) {
        data = {
          ...data,
          csnm: filterClientName,
        };
      }
      // フィルター住所が入力されていればPOST通信と一緒に送信
      if (filterClientAddress) {
        data = {
          ...data,
          addr: filterClientAddress,
        };
      }
      // 月が入力されていればPOST通信と一緒に送信
      if (month) {
        data = {
          ...data,
          m: month,
        };

        totalData = {
          ...totalData,
          m: month,
        };
      }

      const params = encodedParams(data);
      const response = await api.post("/", params);

      const totalParams = encodedParams(totalData);
      const totalResponse = await api.post("/", totalParams);

      setTotal(totalResponse.data.data.jisseki.val);

      console.log(response.data.data.conlst);
      setContractList(response.data.data.conlst);
    } catch (error) {
      console.log(error);
    }
  }, [
    filterClientAddress,
    filterClientName,
    filterClientNo,
    filterStaff,
    month,
    sort,
    year,
  ]);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      // params 内容
      const data = {
        ukey: "trial",
        fid: 16,
      };
      const params = encodedParams(data);
      const response = await api.post("/", params);

      const parseData = response.data.data.usrlst.map((d: StaffDTO) => {
        return {
          label: d.unm,
          value: d.uid,
        };
      });

      load();
      setStaffOptions(parseData);
    })();
  }, [load]);

  // モーダル開
  function handleOpenModal({ mpid, csid }: OpenModalDTO) {
    setModalMpid(String(mpid));
    setModalCsid(String(csid));
    setIsOpen(true);
  }
  return (
    <Container>
      <Header>
        <Title>CONTRACT CUSTOMER</Title>
      </Header>

      <TableFilter>
        <FilterBox style={{ marginLeft: 0 }}>
          <YearSelect date={year} setDate={setYear} />
          <Summary>売上総額: {total ? total : "-"}円</Summary>
        </FilterBox>

        <FilterDetail>
          <FilterBox>
            <h3>契約月</h3>
            <MonthSelect options={monthOptions} setData={setMonth} />
          </FilterBox>
          <FilterBox className="short">
            <h3>顧客No.</h3>
            <Input value={filterClientNo} setData={setFilterClientNo} />
          </FilterBox>
          <FilterBox className="short">
            <h3>顧客名</h3>
            <Input value={filterClientName} setData={setFilterClientName} />
          </FilterBox>
          <FilterBox className="long">
            <h3>住所</h3>
            <Input
              value={filterClientAddress}
              setData={setFilterClientAddress}
            />
          </FilterBox>
          <FilterBox>
            <h3>社員(契約担当)</h3>
            <StyledSelect setData={setFilterStaff} options={staffOptions} />
          </FilterBox>
        </FilterDetail>
      </TableFilter>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadColumn
              className="medium"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 1,
                })
              }
            >
              <p>
                顧客No
                {SortIcon({ sort, sortNumber: 1 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 0,
                })
              }
            >
              <p>
                契約日
                {SortIcon({ sort, sortNumber: 0 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 3,
                })
              }
            >
              <p>
                アポ担当
                {SortIcon({ sort, sortNumber: 3 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 4,
                })
              }
            >
              <p>
                契約担当
                {SortIcon({ sort, sortNumber: 4 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 5,
                })
              }
            >
              <p>
                顧客名
                {SortIcon({ sort, sortNumber: 5 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 6,
                })
              }
            >
              <p>
                顧客カナ
                {SortIcon({ sort, sortNumber: 6 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short-medium"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 7,
                })
              }
            >
              <p>
                電話番号
                {SortIcon({ sort, sortNumber: 7 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="long"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 8,
                })
              }
            >
              <p>
                住所
                {SortIcon({ sort, sortNumber: 8 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short-medium"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 9,
                })
              }
            >
              <p>
                契約金額
                {SortIcon({ sort, sortNumber: 9 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short-medium"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 10,
                })
              }
            >
              <p>
                粗利
                {SortIcon({ sort, sortNumber: 10 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 11,
                })
              }
            >
              <p>
                決済
                {SortIcon({ sort, sortNumber: 11 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 12,
                })
              }
            >
              <p>
                施工店
                {SortIcon({ sort, sortNumber: 12 })}
              </p>
            </TableHeadColumn>

            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 13,
                })
              }
            >
              <p>
                請負契約書
                {SortIcon({ sort, sortNumber: 13 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 14,
                })
              }
            >
              <p>
                クレジット契約書
                {SortIcon({ sort, sortNumber: 14 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 15,
                })
              }
            >
              <p>
                重要事項説明書
                {SortIcon({ sort, sortNumber: 15 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="long"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 16,
                })
              }
            >
              <p>
                その他書類
                {SortIcon({ sort, sortNumber: 16 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 17,
                })
              }
            >
              <p>
                アポ取得日
                {SortIcon({ sort, sortNumber: 17 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 18,
                })
              }
            >
              <p>
                商談日
                {SortIcon({ sort, sortNumber: 18 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 19,
                })
              }
            >
              <p>
                現調日
                {SortIcon({ sort, sortNumber: 19 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 20,
                })
              }
            >
              <p>
                工事日①
                {SortIcon({ sort, sortNumber: 20 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 21,
                })
              }
            >
              <p>
                工事日②
                {SortIcon({ sort, sortNumber: 21 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 22,
                })
              }
            >
              <p>
                工事日③
                {SortIcon({ sort, sortNumber: 22 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 23,
                })
              }
            >
              <p>
                完工日
                {SortIcon({ sort, sortNumber: 23 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 24,
                })
              }
            >
              <p>
                連系日
                {SortIcon({ sort, sortNumber: 24 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 25,
                })
              }
            >
              <p>
                保証申請完了日
                {SortIcon({ sort, sortNumber: 25 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 26,
                })
              }
            >
              <p>
                商材１
                {SortIcon({ sort, sortNumber: 26 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 27,
                })
              }
            >
              <p>
                メーカー１
                {SortIcon({ sort, sortNumber: 27 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 28,
                })
              }
            >
              <p>
                KW容量１
                {SortIcon({ sort, sortNumber: 28 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 29,
                })
              }
            >
              <p>
                モジュール１
                {SortIcon({ sort, sortNumber: 29 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 30,
                })
              }
            >
              <p>
                パワコン
                {SortIcon({ sort, sortNumber: 30 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 31,
                })
              }
            >
              <p>
                品番１
                {SortIcon({ sort, sortNumber: 31 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 32,
                })
              }
            >
              <p>
                有償保証１
                {SortIcon({ sort, sortNumber: 32 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 33,
                })
              }
            >
              <p>
                商材２
                {SortIcon({ sort, sortNumber: 33 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 34,
                })
              }
            >
              <p>
                メーカー２
                {SortIcon({ sort, sortNumber: 34 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 35,
                })
              }
            >
              <p>
                KW容量２
                {SortIcon({ sort, sortNumber: 35 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 36,
                })
              }
            >
              <p>
                有償保証２
                {SortIcon({ sort, sortNumber: 36 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 37,
                })
              }
            >
              <p>
                商材３
                {SortIcon({ sort, sortNumber: 37 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 38,
                })
              }
            >
              <p>
                メーカー３
                {SortIcon({ sort, sortNumber: 38 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 39,
                })
              }
            >
              <p>
                品番３
                {SortIcon({ sort, sortNumber: 39 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 40,
                })
              }
            >
              <p>
                有償保証３
                {SortIcon({ sort, sortNumber: 40 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 41,
                })
              }
            >
              <p>
                商材４
                {SortIcon({ sort, sortNumber: 41 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 42,
                })
              }
            >
              <p>
                メーカー４
                {SortIcon({ sort, sortNumber: 42 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 43,
                })
              }
            >
              <p>
                品番４
                {SortIcon({ sort, sortNumber: 43 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              className="short"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 44,
                })
              }
            >
              <p>
                有償保証４
                {SortIcon({ sort, sortNumber: 44 })}
              </p>
            </TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {contractList &&
            contractList.map((item) => (
              <BodyRow
                key={String(item.mpid) + String(item.csid) + String(item.cpno)}
                onClick={() =>
                  handleOpenModal({ mpid: item.mpid, csid: item.csid })
                }
              >
                <TableBodyColumn className="medium">
                  <p>{item.csid}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.condd}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.ap_uid}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con_uid}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.unm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.unm_kana}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short-medium">
                  <p>{item.telno}</p>
                </TableBodyColumn>
                <TableBodyColumn className="long">
                  <p>{item.addr}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short-medium">
                  <p>{item.conval}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short-medium">
                  <p>{item.conarari}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.payment}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.const_shop}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.keiyaku_syo}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.credit_syo}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.jyuyo_syo}</p>
                </TableBodyColumn>
                <TableBodyColumn className="long">
                  <p>{item.other_syo}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.apdt}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.syodt}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.gendt}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.kujdt1}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.kujdt2}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.kujdt3}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.kandt}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.rendt}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.hosdt}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[0]?.pnm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[0]?.company}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[0]?.capacity}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[0]?.module}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[0]?.powercon}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[0]?.hinban}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[0]?.hosyo}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[1]?.pnm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[1]?.company}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[1]?.capacity}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[1]?.hosyo}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[2]?.pnm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[2]?.company}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[2]?.hinban}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[2]?.hosyo}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[3]?.pnm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[3]?.company}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[3]?.hinban}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.con[3]?.hosyo}</p>
                </TableBodyColumn>
              </BodyRow>
            ))}
        </TableBody>
      </Table>

      <ClientInformationModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        mpid={modalMpid}
        csid={modalCsid}
      />
    </Container>
  );
}

const monthOptions = [
  { value: 1, label: "1月" },
  { value: 2, label: "2月" },
  { value: 3, label: "3月" },
  { value: 4, label: "4月" },
  { value: 5, label: "5月" },
  { value: 6, label: "6月" },
  { value: 7, label: "7月" },
  { value: 8, label: "8月" },
  { value: 9, label: "9月" },
  { value: 10, label: "10月" },
  { value: 11, label: "11月" },
  { value: 12, label: "12月" },
];
