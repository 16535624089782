import styled, { css } from "styled-components";
import { colors, fonts } from "../../styles/theme";

interface Props {
  isSelected?: boolean;
}
export const Container = styled.div`
  width: calc(100vw - 305px);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  width: 76.24%;

  margin: 45px auto 20px;
  border-bottom: 1px solid ${colors.border_300};
`;

export const Title = styled.h2`
  font-size: 1.6875rem;
  font-family: ${fonts.secondary};

  font-weight: 600;
  color: ${colors.text_detail};

  padding-bottom: 15px;

  margin-right: 50px;
`;

export const StyledLink = styled.a<Props>`
  font-size: 1.125rem;
  font-family: ${fonts.primary};

  font-weight: 600;
  color: ${({ isSelected }) => (isSelected ? colors.main : colors.text_detail)};
  text-decoration: none;

  padding: 0 2px 15px;
  margin-right: 40px;

  ${({ isSelected }) =>
    isSelected
      ? css`
          border-bottom: 3px solid ${colors.main};
        `
      : css`
          padding-bottom: 18px;
        `}
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: 16px;
`;

export const TableFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 76.24%;
  margin: 40px auto 15px;
`;

export const FilterBox = styled.div`
  width: 100%;
  min-width: 160px;
  max-width: 270px;

  svg {
    color: ${colors.title};
  }
`;

export const FilterDetail = styled.span`
  color: ${colors.text};
  font-size: 1.125rem;
  font-family: ${fonts.primary};

  font-weight: 500;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;

  width: 76.24%;

  height: 75.2%;

  margin: 0 auto;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  overflow: scroll;
  white-space: nowrap;

  position: relative;
`;

export const TableHead = styled.div`
  display: flex;

  position: sticky;
  top: 0;
`;

export const TableBody = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;

  height: 73px;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: ${colors.background_primary};

  border-bottom: 1px solid ${colors.border_200};

  div.short {
    width: 160px;
  }

  div.short-medium {
    width: 160px;
  }
  div.medium {
    width: 180px;
  }
  div.long {
    width: 350px;
  }
  div.extra-large {
    width: 420px;
    justify-content: flex-start;
  }
`;

export const BodyRow = styled.div`
  display: flex;
  align-items: center;

  height: 73px;

  border: none;
  border-bottom: 1px solid ${colors.border_200};
  background-color: ${colors.background_primary};
  outline: none;

  div.short {
    width: 160px;
    justify-content: center;
  }

  div.short-medium {
    width: 160px;
    justify-content: center;
  }
  div.medium {
    width: 180px;
    justify-content: center;
  }
  div.long {
    width: 350px;
    justify-content: flex-start;
  }
  div.extra-large {
    width: 420px;
    justify-content: flex-start;
  }
`;

export const TableHeadColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  padding: 0 16px;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.title};
    font-size: 0.875rem;
    font-family: ${fonts.primary};

    font-weight: 600;

    svg {
      margin-left: 15px;
    }
  }
`;

export const TableBodyColumn = styled.div`
  display: flex;
  align-items: center;

  padding: 0 25px;

  p {
    font-size: 0.875rem;
    font-family: ${fonts.primary};
    font-weight: 600;
    color: ${colors.text_detail};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  strong {
    font-size: 0.8125rem;
    font-family: ${fonts.secondary};
    font-weight: 600;
    color: ${colors.text_detail};
  }
`;
