import {
  MdEvent,
  MdAssignment,
  MdInsertChart,
  MdOutlineCardTravel,
  MdOutlineCardMembership,
  MdAccountBox,
  MdSettings,
  MdRoom,
  MdHome,
  MdForum,
} from "react-icons/md";

import { IoMdStopwatch } from "react-icons/io";

import { Container, SidebarNav, StyledNavLink } from "./styles";

export function Sidebar() {
  return (
    <Container>
      <SidebarNav>
        <ul>
          <StyledNavLink to="/">
            <li>
              <IoMdStopwatch size={25} />
              <p>タイムライン</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/event">
            <li>
              <MdEvent size={25} />
              <p>イベント</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/daily-report">
            <li>
              <MdAssignment size={25} />
              <p>日報</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/dashboard">
            <li>
              <MdInsertChart size={25} />
              <p>成績表</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/new-customer">
            <li>
              <MdOutlineCardTravel size={25} />
              <p>新規顧客</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/contract">
            <li>
              <MdOutlineCardMembership size={25} />
              <p>契約</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/staff">
            <li>
              <MdAccountBox size={25} />
              <p>社員情報</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/setting">
            <li>
              <MdSettings size={25} />
              <p>設定</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/gps">
            <li>
              <MdRoom size={25} />
              <p>GPS表示</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/visit">
            <li>
              <MdHome size={25} />
              <p>訪問実績</p>
            </li>
          </StyledNavLink>
          <StyledNavLink to="/message">
            <li>
              <MdForum size={25} />
              <p>メッセージ</p>
            </li>
          </StyledNavLink>
        </ul>
      </SidebarNav>
    </Container>
  );
}
