import Select, { components, PlaceholderProps } from "react-select";

import { Container } from "./styles";

const Placeholder = ({ ...rest }: PlaceholderProps) => {
  return <components.Placeholder {...rest} />;
};

interface Props {
  options: {
    label: string;
    value: string;
  }[];
  setData: (data: any) => void;
}

export function StyledSelect({ options, setData }: Props) {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      minWidth: "140px",
      maxWidth: "180px",
    }),
    control: () => ({
      padding: "6px 20px",
      border: "0",
      outline: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "0.9375rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      fontWeight: 500,
      color: "#A2A6B1",
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: "0.9375rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      fontWeight: 500,
      color: "#A2A6B1",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "0.9375rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      fontWeight: 500,
      color: "#A2A6B1",
    }),
  };

  function handleChangeData(newValue: any) {
    setData(newValue.value);
  }
  return (
    <Container>
      <Select
        closeMenuOnSelect={false}
        components={{
          Placeholder,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        placeholder={""}
        options={options}
        styles={customStyles}
        onChange={(newValue) => handleChangeData(newValue)}
      />
    </Container>
  );
}
