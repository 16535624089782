import styled from "styled-components";
import { colors, fonts } from "../../../../styles/theme";

export const Section = styled.div`
  width: 20%;
  min-width: 250px;
  max-width: 300px;

  margin-right: 30px;
`;

export const Label = styled.label`
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-family: ${fonts.primary};

  color: ${colors.title};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  display: flex;
  vertical-align: top;
  flex: 1;

  padding: 6px 16px;

  font-size: 1rem;
  font-family: ${fonts.primary};
  color: ${colors.title};

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};
  border-radius: 3px;
`;

export const Unit = styled.p`
  font-size: 1rem;
  font-family: ${fonts.primary};
  color: ${colors.title};

  padding-left: 5px;
`;
