import Select, { components, PlaceholderProps } from "react-select";
import { FaSort } from "react-icons/fa";

import { Container } from "./styles";

interface Props {
  options: {
    label: string;
    value: number;
  }[];
  setData: (data: any) => void;
  data: {
    label: string;
    value: number;
  };
}

export function MonthSelect({ options, setData, data }: Props) {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      minWidth: "100px",
    }),
    control: () => ({
      display: "flex",
      alignItems: "center",
      border: "0",
      padding: "4px 15px",
      outline: "none",
      fontSize: "0.9375rem",
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: "#A2A6B1",
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: "0.9375rem",
      fontFamily: "Montserrat",
      fontWeight: 600,
      color: "#A2A6B1",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "0.9375rem",
      fontFamily: "Montserrat",
      fontWeight: 600,
      color: "#A2A6B1",
    }),
  };

  function handleChangeData(newValue: any) {
    setData(newValue.value);
  }
  return (
    <Container>
      <Select
        closeMenuOnSelect={false}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        value={data}
        options={options}
        styles={customStyles}
        onChange={(newValue) => handleChangeData(newValue)}
      />
      <FaSort size={14} color="#A2A6B1" />
    </Container>
  );
}
