import { useEffect, useState } from "react";
import { StaffInformationModal } from "../../components/Modals/StaffInformationModal";
import { StaffDTO } from "../../dtos/StaffDTO";
import api from "../../services/api";
import { encodedParams } from "../../utils/encodedParams";

import {
  Container,
  Header,
  Title,
  TableFilter,
  CreateNewStaffButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  BodyRow,
  TableHeadColumn,
  TableBodyColumn,
  UrlNotificationButton,
  Avatar,
  AvatarGhost,
} from "./styles";

export function Staff() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalUid, setModalUid] = useState("");

  const [staffList, setStaffList] = useState<StaffDTO[]>();

  // api初期読み込み
  useEffect(() => {
    (async () => {
      // params 内容
      const staffData = {
        ukey: "trial",
        fid: 16,
      };
      const staffParams = encodedParams(staffData);
      const staffResponse = await api.post("/", staffParams);

      setStaffList(staffResponse.data.data.usrlst);
    })();
  }, []);

  function handleOpenModal(uid: number) {
    setModalUid(String(uid));
    setIsOpen(true);
  }

  return (
    <Container>
      <Header>
        <Title>STAFF INFORMATION</Title>
      </Header>

      <TableFilter>
        <CreateNewStaffButton onClick={() => setIsOpen(true)}>
          新規登録
        </CreateNewStaffButton>
      </TableFilter>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadColumn className="medium">
              <p>No.</p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>部署</p>
            </TableHeadColumn>
            <TableHeadColumn className="avatar">
              <p>社員名</p>
            </TableHeadColumn>
            <TableHeadColumn className="long">
              <p>Gmailアドレス</p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>権限</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>URL通知</p>
            </TableHeadColumn>
            <TableHeadColumn className="end" />
          </TableRow>
        </TableHead>
        <TableBody>
          {staffList &&
            staffList.map((item) => (
              <BodyRow key={item.uid} onClick={() => handleOpenModal(item.uid)}>
                <TableBodyColumn className="medium">
                  <p>{item.uid}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.bnm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="avatar">
                  {item.photo ? (
                    <Avatar src={item.photo} alt={item.unm} />
                  ) : (
                    <AvatarGhost />
                  )}
                  <p>{item.unm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="long">
                  <p>{item.gmail}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.is_mng}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short-medium">
                  <UrlNotificationButton>URL通知</UrlNotificationButton>
                </TableBodyColumn>
                <TableBodyColumn className="end" />
              </BodyRow>
            ))}
        </TableBody>
      </Table>

      <StaffInformationModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        uid={modalUid}
      />
    </Container>
  );
}
