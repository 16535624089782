import { useEffect, useState } from "react";
import { ClientInformationModal } from "../../components/Modals/ClientInformationModal";

import { SelectDate } from "../../components/SelectDate";
import {
  DailyReportJissekiDTO,
  DailyReportTotalDTO,
} from "../../dtos/DailyReportDTO";
import api from "../../services/api";
import { encodedParams } from "../../utils/encodedParams";

import {
  Container,
  Header,
  Title,
  FilterBox,
  TodayProgressContainer,
  Border,
  TodayProgressContent,
  Progress,
  TableFilter,
  Table,
  TableHead,
  TableBody,
  TableRow,
  BodyRow,
  TableHeadColumn,
  TableBodyColumn,
  BodyRowClose,
  BodyRowOpen,
  BodyRowTableHead,
  BodyRowTableHeadRow,
  BodyRowTableBody,
  BodyRowTableBodyRow,
} from "./styles";

export function DailyReport() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMpid, setModalMpid] = useState("");
  const [modalCsid, setModalCsid] = useState("");

  const [filterDate, setFilterDate] = useState<Date | null>();
  const [total, setTotal] = useState<DailyReportTotalDTO>();
  const [dailyList, setDailyList] = useState<DailyReportJissekiDTO[]>();

  useEffect(() => {
    (async () => {
      try {
        let y = new Date().getFullYear();
        let m = new Date().getMonth() + 1;
        let d = new Date().getDate();

        if (filterDate) {
          y = filterDate.getFullYear();
          m = filterDate.getMonth() + 1;
          d = filterDate.getDate();
        }

        const data = {
          ukey: "trial",
          fid: 27,
          y,
          m,
          d,
        };
        const params = encodedParams(data);
        const response = await api.post("/", params);

        setDailyList(response.data.data.jsklst);
        setTotal(response.data.data.total);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [filterDate]);

  return (
    <Container>
      <Header>
        <Title>DAILY REPORT</Title>
      </Header>

      <TodayProgressContainer>
        <h3>【当日の結果】</h3>
        <Border />
        <TodayProgressContent>
          <Progress>
            <p>訪問数</p>
            <strong>{total && total.vcnt ? total.vcnt : "-"}</strong>
          </Progress>
          <Progress>
            <p>AP</p>
            <strong>{total && total.ncnt ? total.ncnt : "-"}</strong>
          </Progress>
          <Progress>
            <p>再訪</p>
            <strong>{total && total.rcnt ? total.rcnt : "-"}</strong>
          </Progress>
          <Progress>
            <p>契約</p>
            <strong>{total && total.ccnt ? total.ccnt : "-"}</strong>
          </Progress>
        </TodayProgressContent>
      </TodayProgressContainer>

      <TableFilter>
        <FilterBox>
          <SelectDate
            date={filterDate}
            setDate={setFilterDate}
            icon="calendar"
          />
        </FilterBox>
      </TableFilter>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadColumn className="short-medium">
              <p>社員</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>訪問数</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>AP</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>再訪</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>契約</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>売上金額</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>開始時刻</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>終了時刻</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>訪問時間</p>
            </TableHeadColumn>
            <TableHeadColumn className="short-medium">
              <p>訪問距離</p>
            </TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {dailyList &&
            dailyList.map((item) => (
              <BodyRow key={item.uid}>
                <BodyRowClose>
                  <TableBodyColumn className="short-medium">
                    <strong>{item.unm}</strong>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.vcnt}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.ncnt}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.rcnt}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.ccnt}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.val}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.stm}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.etm}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.vtms}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{item.vdst}</p>
                  </TableBodyColumn>
                </BodyRowClose>
                <BodyRowOpen>
                  <BodyRowTableHead>
                    <BodyRowTableHeadRow>
                      <TableHeadColumn className="short-medium"></TableHeadColumn>
                      <TableHeadColumn className="extra-large">
                        <p>
                          顧客名
                          <br />
                          住所/商品名
                        </p>
                      </TableHeadColumn>
                      <TableHeadColumn className="short-medium">
                        <p>訪問時刻</p>
                      </TableHeadColumn>
                      <TableHeadColumn className="short-medium">
                        <p>顧客感度</p>
                      </TableHeadColumn>
                      <TableHeadColumn className="short-medium">
                        <p>AP日</p>
                      </TableHeadColumn>
                      <TableHeadColumn className="short-medium">
                        <p>契約日</p>
                      </TableHeadColumn>
                    </BodyRowTableHeadRow>
                  </BodyRowTableHead>
                  <BodyRowTableBody>
                    {item.detail &&
                      item.detail.map((d) => (
                        <BodyRowTableBodyRow key={d.csnm}>
                          <TableBodyColumn className="short-medium"></TableBodyColumn>
                          <TableBodyColumn className="extra-large">
                            <p>
                              {d.csnm}
                              <br />
                              {d.add}
                            </p>
                          </TableBodyColumn>
                          <TableBodyColumn className="short-medium">
                            <p>{d.tm}</p>
                          </TableBodyColumn>
                          <TableBodyColumn className="short-medium">
                            <p>{d.pnm}</p>
                          </TableBodyColumn>
                          <TableBodyColumn className="short-medium">
                            <p>{d.apdt}</p>
                          </TableBodyColumn>
                          <TableBodyColumn className="short-medium">
                            <p>{d.condt}</p>
                          </TableBodyColumn>
                        </BodyRowTableBodyRow>
                      ))}
                  </BodyRowTableBody>
                </BodyRowOpen>
              </BodyRow>
            ))}
        </TableBody>
      </Table>

      <ClientInformationModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        mpid={modalMpid}
        csid={modalCsid}
      />
    </Container>
  );
}
