import styled from "styled-components";
import { colors, fonts } from "../../../../../../styles/theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 40px;
  margin-bottom: auto;
`;

export const Section = styled.section`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-bottom: 37px;
`;

export const DeleteSection = styled.div`
  display: flex;
  border-radius: 3px;

  width: 20%;
  min-width: 250px;
  max-width: 300px;

  margin-right: 30px;

  font-size: 0.875rem;
  font-family: ${fonts.primary};

  color: ${colors.title};

  input[type="checkbox"]:checked + label {
    background-color: ${colors.main};
    color: ${colors.background_primary};

    border-radius: 3px;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    margin-left: 10px;

    height: 1.25rem;
    width: 1.25rem;

    border-radius: 3px;

    border: 1px solid ${colors.border_200};
    background-color: ${colors.background_primary};

    color: ${colors.background_primary};
  }
`;
