import { HTMLAttributes } from "react";
import { Container } from "./styles";

interface Props extends HTMLAttributes<HTMLInputElement> {
  id: string;
  title: string;
  containerStyle?: object;
}

export function CheckboxInput({ id, title, containerStyle, ...rest }: Props) {
  return (
    <Container style={containerStyle}>
      <input id={id} type="checkbox" {...rest} />
      <label htmlFor={id}>
        <span>&#10003;</span>
        {title}
      </label>
    </Container>
  );
}
