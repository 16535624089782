import styled, { keyframes } from "styled-components";
import { colors, fonts } from "../../styles/theme";

export const Container = styled.div`
  width: calc(100vw - 305px);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 76.24%;

  margin: 45px auto 20px;
`;

export const Title = styled.h2`
  font-size: 1.6875rem;
  font-family: ${fonts.secondary};

  font-weight: 600;
  color: ${colors.text_detail};
`;

export const FilterBox = styled.div`
  margin-left: 10px;
  width: 100%;
  min-width: 160px;
  max-width: 270px;

  svg {
    color: ${colors.title};
  }
`;

export const TodayProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;

  width: 76.24%;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};
  border-radius: 4px;

  h3 {
    font-size: 1.125rem;
    font-family: ${fonts.primary};

    font-weight: 400;
    color: ${colors.text};

    padding-top: 26px;
  }
`;

export const Border = styled.div`
  width: 90%;
  height: 1px;

  background-color: ${colors.border_200};
  margin-top: 12px;
`;

export const TodayProgressContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 26px auto 22px;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 40px;

  p {
    text-align: center;
    font-size: 1rem;
    font-family: ${fonts.primary};

    font-weight: 400;
    color: ${colors.text};
  }

  strong {
    margin-top: 8px;
    text-align: center;
    font-size: 1.6875rem;
    font-family: ${fonts.secondary};

    font-weight: 600;
    color: ${colors.text_detail};
  }
`;

export const TableFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 76.24%;
  margin: 40px auto 15px;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;

  width: 76.24%;

  height: 54.69%;

  margin: 0 auto;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  overflow: scroll;
  white-space: nowrap;

  position: relative;
`;

export const TableHead = styled.div`
  display: flex;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: ${colors.background_primary};

  position: sticky;
  top: 0;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    height: 0;
  }
  70% {
    opacity: 0;
    height: 200px;
  }
  100% {
    opacity: 1;
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;

  details[open] > div {
    animation: ${fadeIn} 0.4s linear;
  }

  details[open] > summary {
    background-color: ${colors.border_200};
  }
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;

  height: 73px;

  border-bottom: 1px solid ${colors.border_200};

  div.short {
    width: 122px;
  }

  div.short-medium {
    width: 160px;
  }
  div.medium {
    width: 180px;
  }
  div.long {
    width: 320px;
  }
  div.extra-large {
    width: 400px;
    justify-content: flex-start;
  }
`;

export const BodyRow = styled.details`
  display: flex;
  flex-direction: column;

  border: none;
  border-bottom: 1px solid ${colors.border_200};
  background-color: ${colors.background_primary};
  outline: none;
`;

export const TableHeadColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  padding: 0 25px;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.title};
    font-size: 0.875rem;
    font-family: ${fonts.primary};

    font-weight: 600;

    svg {
      margin-left: 15px;
    }
  }
`;

export const TableBodyColumn = styled.div`
  display: flex;
  align-items: center;

  padding: 0 25px;

  p {
    font-size: 0.875rem;
    font-family: ${fonts.primary};
    font-weight: 600;
    color: ${colors.text_detail};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  strong {
    font-size: 0.8125rem;
    font-family: ${fonts.secondary};
    font-weight: 600;
    color: ${colors.text_detail};
  }
`;

export const BodyRowClose = styled.summary`
  display: flex;
  align-items: center;

  height: 73px;

  border: none;
  border-bottom: 1px solid ${colors.border_200};
  background-color: ${colors.background_primary};
  outline: none;

  div.short-medium {
    width: 160px;
    justify-content: center;
  }
`;

export const BodyRowOpen = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
`;

export const BodyRowTableHead = styled.div`
  display: flex;
  flex: 1;

  background-color: ${colors.background_primary};
`;

export const BodyRowTableHeadRow = styled.div`
  display: flex;
  align-items: center;

  height: 73px;

  border-bottom: 1px solid ${colors.border_200};

  div.short-medium {
    width: 160px;
  }

  div.extra-large {
    width: 630px;
    justify-content: flex-start;
    text-align: left;
  }
`;

export const BodyRowTableBody = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
`;

export const BodyRowTableBodyRow = styled.div`
  display: flex;
  align-items: center;

  height: 73px;

  div.short-medium {
    width: 160px;
    justify-content: center;
  }

  div.extra-large {
    width: 630px;
    justify-content: flex-start;
    text-align: left;
  }
`;

// ul > li {
//   background-color: paleturquoise;
//   text-align: center;
//   width: 300px;
//   /* 閉じている状態 */
//   border-top: 0 white solid;
//   padding-top: 0;
//   padding-bottom: 0;
//   /* 閉じるアニメーション */
//   transition:
//     border-top     .3s ease-out,
//     padding-top    .3s ease-out,
//     padding-bottom .3s ease-out;
// }
// ul.is-open > li {
//   /* 開いている状態 */
//   border-top: 1px white solid;
//   padding-top: 13px;
//   padding-bottom: 13px;
//   /* 開くアニメーション */
//   transition:
//     border-top     .3s ease-out,
//     padding-top    .3s ease-out,
//     padding-bottom .3s ease-out;
// }
// ul > li > p {
//   /* 閉じている状態 */
//   line-height: 0;
//   opacity: 0;
//   visibility: hidden;
//   /* 閉じるアニメーション */
//   transition:
//     line-height .3s ease-out,
//     opacity     .1s linear,
//     visibility  .1s linear;
// }
// ul.is-open > li > p {
//   /* 開いている状態 */
//   line-height: 1.5;
//   opacity: 1;
//   visibility: visible;
//   /* 開くアニメーション */
//   transition:
//     line-height .3s ease-out,
//     opacity     .1s linear .1s,
//     visibility  .1s linear .1s;
// }
