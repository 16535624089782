import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { StaffDTO } from "../../../dtos/StaffDTO";
import api from "../../../services/api";
import { encodedParams } from "../../../utils/encodedParams";
import { ImageSection } from "../Form/ImageSection";
import { InputSection } from "../Form/InputSection";
import { SaveButton } from "../Form/SaveButton";
import { StaffImageSection } from "../Form/StaffImageSection";

import { ReactModal } from "../ReactModal";

import {
  Container,
  Header,
  Content,
  Section,
  DeleteSection,
  DeleteButton,
  ButtonSection,
} from "./styles";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (data: boolean) => void;
  uid: string;
}

interface FormData {
  name: string;
  amount: string;
}

export function StaffInformationModal({ modalIsOpen, setIsOpen, uid }: Props) {
  const { control, handleSubmit, setValue, getValues, resetField } = useForm();
  const [loading, setLoading] = useState(true);

  const [busyo, setBusyo] = useState<[]>();

  const [staffDetail, setStaffDetail] = useState<StaffDTO>();

  const setValueInInput = useCallback((data: StaffDTO) => {
    console.log(data);

    setValue("bnm", data.bnm);
    setValue("unm", data.unm);
    setValue("gmail", data.gmail);
    setValue("photo", data.photo);
  }, []);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      setLoading(true);
      // params 内容
      const data = {
        ukey: "trial",
        fid: 16,
        uid: Number(uid),
        rmflg: 0,
        is_all: 1,
      };
      const params = encodedParams(data);
      const response = await api.post("/", params);

      console.log("data", data);
      console.log("response", response.data);
      // // 社員一覧
      // const staffData = {
      //   ukey: "trial",
      //   fid: 16,
      // };
      // const staffParams = encodedParams(staffData);
      // const staffResponse = await api.post("/", staffParams);

      setStaffDetail(response.data.data); // 社員情報の保管
      // setStaff(staffResponse.data.data.usrlst); // スタッフリスト情報

      setValueInInput(response.data.data); // 現在入力されている分をセット
      setLoading(false);
    })();
  }, [uid]);

  function handleSave(form: FormData) {
    setValue("電力受給No", "123456");

    console.log(form);
  }

  return (
    <ReactModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <h2>【社員情報】</h2>
        </Header>

        <Content>
          <Section>
            <InputSection title="部署" name="bnm" control={control} />
            <StaffImageSection
              title="社員写真"
              name="photo"
              control={control}
            />
          </Section>
          <Section>
            <InputSection title="ユーザー名" name="unm" control={control} />
            <InputSection
              title="Gmailアドレス"
              name="gmail"
              control={control}
            />
          </Section>
          <Section>
            <DeleteSection>
              <p>管理者</p>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      type="checkbox"
                      id="is_mng"
                      onChange={onChange}
                      value={value}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="is_mng">&#10003;</label>
                  </>
                )}
                name="is_mng"
              />
            </DeleteSection>
          </Section>

          <ButtonSection>
            <SaveButton onClick={handleSubmit(handleSave)} />
            <DeleteButton>削除</DeleteButton>
          </ButtonSection>
        </Content>
      </Container>
    </ReactModal>
  );
}
