import styled from "styled-components";
import { colors, fonts } from "../../styles/theme";

export const Container = styled.div`
  width: calc(100vw - 305px);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  width: 76.24%;

  margin: 45px auto 0;
  border-bottom: 1px solid ${colors.border_300};
`;

export const Title = styled.h2`
  font-size: 1.6875rem;
  font-family: ${fonts.secondary};

  font-weight: 600;
  color: ${colors.text_detail};

  padding-bottom: 15px;
`;

export const TableFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 76.24%;
  margin: 30px auto 15px;
`;

export const CreateNewStaffButton = styled.button`
  width: 180px;
  height: 54px;

  background-color: ${colors.main};
  color: ${colors.background_primary};

  border: none;
  outline: none;

  border-radius: 4px;
  box-shadow: 0 4px 0 #369fa8;

  transition: background-color 0.3s, box-shadow 0.3s;

  :hover {
    background-color: ${colors.main};
  }

  &:active {
    transform: translateY(3px);
    background-color: ${colors.main};
    box-shadow: inset 3px 3px 0 #369fa8;
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;

  width: 76.24%;

  height: 75.2%;

  margin: 0 auto;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  overflow: scroll;
  white-space: nowrap;

  position: relative;
`;

export const TableHead = styled.div`
  display: flex;

  position: sticky;
  top: 0;
`;

export const TableBody = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;

  height: 73px;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: ${colors.background_primary};

  div.short {
    width: 122px;
  }

  div.short-medium {
    width: 200px;
  }
  div.medium {
    width: 180px;
  }
  div.long {
    width: 320px;
  }

  div.end {
    width: 371px;
  }
  div.avatar {
    width: 200px;
  }
`;

export const BodyRow = styled.button`
  display: inline-flex;
  align-items: center;

  height: 73px;

  border: none;
  border-bottom: 1px solid ${colors.border_200};
  background-color: ${colors.background_primary};
  outline: none;

  div.short {
    width: 122px;
    justify-content: center;
  }

  div.short-medium {
    width: 200px;
    justify-content: center;
  }

  div.medium {
    width: 180px;
    justify-content: center;
  }

  div.long {
    width: 320px;
    justify-content: center;
  }

  div.end {
    width: 371px;
  }

  div.avatar {
    width: 200px;
    justify-content: flex-start;
  }
`;

export const Avatar = styled.img`
  width: 2.875rem;
  height: 2.875rem;

  border-radius: 50%;

  margin-right: 15.5px;
  border: 1px solid #fff;
`;
export const AvatarGhost = styled.div`
  width: 2.875rem;
  height: 2.875rem;

  border-radius: 50%;

  background-color: #ccc;

  margin-right: 15.5px;
`;

export const TableHeadColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  padding: 0 16px;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.title};
    font-size: 0.875rem;
    font-family: ${fonts.primary};

    font-weight: 600;

    svg {
      margin-left: 15px;
    }
  }
`;

export const TableBodyColumn = styled.div`
  display: flex;
  align-items: center;

  padding: 0 25px;

  p {
    font-size: 0.875rem;
    font-family: ${fonts.primary};
    font-weight: 600;
    color: ${colors.text_detail};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  strong {
    font-size: 0.8125rem;
    font-family: ${fonts.secondary};
    font-weight: 600;
    color: ${colors.text_detail};
  }
`;

export const UrlNotificationButton = styled.button`
  width: 150px;
  height: 32px;

  background-color: ${colors.text_detail};
  color: ${colors.background_primary};

  border: none;
  outline: none;

  border-radius: 4px;
  box-shadow: 0 5px 0 ${colors.title};

  transition: background-color 0.3s, box-shadow 0.3s;

  :hover {
    background-color: ${colors.main};
  }

  &:active {
    transform: translateY(3px);
    background-color: ${colors.main};
    box-shadow: inset 3px 3px 0 ${colors.title};
  }
`;
