import { forwardRef, useRef } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import Input from "./Input";
import {
  Control,
  Controller,
  FieldValues,
  UseFormResetField,
} from "react-hook-form";

import { Section, TitleContainer, SectionTitle, ClearBox } from "./styles";

registerLocale("ja", ja);

interface Props {
  control: Control;
  name: string;
  title: string;
  resetField: UseFormResetField<FieldValues>;
}

const CustomInput = forwardRef((props: any, ref) => {
  return <Input {...props} ref={ref} />;
});

export function SelectDateSection({ resetField, title, name, control }: Props) {
  const inputRef = useRef(null);

  function handleClearInput() {
    resetField(name);
  }

  return (
    <Section>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          console.log("date", value);

          return (
            <>
              <TitleContainer>
                <SectionTitle>{title}</SectionTitle>
                <ClearBox onClick={handleClearInput} empty={!!value}>
                  {!!value && <span>&#10003;</span>}
                </ClearBox>
              </TitleContainer>
              <DatePicker
                locale="ja"
                dateFormat="yyyy.MM.dd"
                selected={value}
                customInput={
                  <CustomInput
                    inputRef={inputRef}
                    onChange={onChange}
                    value={value}
                  />
                }
                onChange={onChange}
              />
            </>
          );
        }}
      />
    </Section>
  );
}
