import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

import { NavLink } from "react-router-dom";

export const SettingContainer = styled.main`
  width: calc(100vw - 305px);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  width: 76.24%;

  margin: 45px auto 20px;
  border-bottom: 1px solid ${colors.border_300};
`;

export const Title = styled.h2`
  font-size: 1.6875rem;
  font-family: ${fonts.secondary};

  font-weight: 600;
  color: ${colors.text_detail};

  padding-bottom: 15px;

  margin-right: 50px;
`;

export const StyledLink = styled(NavLink)`
  font-size: 1.125rem;
  font-family: ${fonts.primary};

  font-weight: 600;
  color: ${colors.text_detail};
  text-decoration: none;

  padding: 0 2px 15px;
  margin-right: 40px;

  padding-bottom: 18px;

  &[aria-current] {
    padding-bottom: none;
    color: ${colors.main};

    border-bottom: 3px solid ${colors.main};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: 16px;
`;

export const Container = styled.div`
  width: 76.24%;

  margin: 40px auto auto;

  color: ${colors.title};
  font-size: 0.9375rem;
  font-family: ${fonts.primary};
  font-weight: 500;
`;

export const Section = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-bottom: 37px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  color: ${colors.title};
  font-size: 0.9375rem;
  font-family: ${fonts.primary};
  font-weight: 500;

  margin-right: 70px;
`;
