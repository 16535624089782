import styled from "styled-components";
import { colors, fonts } from "../../styles/theme";

interface Props {
  modalIsOpen: boolean;
}

export const Container = styled.div`
  width: calc(100vw - 305px);
  display: flex;
  flex-direction: column;

  position: relative;

  overflow-y: scroll;

  :-webkit-scrollbar {
    width: 0;
  }

  @media print {
    width: 100%;
    height: 100%;

    overflow-y: hidden;
  }
`;

export const TopFixContainer = styled.div<Props>`
  position: sticky;
  top: 0;

  background-color: ${colors.background_secondary};
  z-index: ${({ modalIsOpen }) => (modalIsOpen ? 0 : 1)};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 45px auto 20px;

  width: 76.24%;
`;

export const Title = styled.h2`
  font-size: 1.6875rem;
  font-family: ${fonts.secondary};

  font-weight: 600;
  color: ${colors.text_detail};
`;

export const SelectDateContainer = styled.div`
  width: 24.31%;
  min-width: 320px;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: 16px;
`;

export const TodayProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 26px auto 22px;

  padding: 26px 16px;

  width: 76.24%;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};
  border-radius: 4px;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 40px;

  p {
    text-align: center;
    font-size: 1rem;
    font-family: ${fonts.primary};

    font-weight: 400;
    color: ${colors.text};
  }

  strong {
    margin-top: 8px;
    text-align: center;
    font-size: 1.6875rem;
    font-family: ${fonts.secondary};

    font-weight: 600;
    color: ${colors.text_detail};
  }
`;

export const SelectInput = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;

  cursor: pointer;

  label {
    cursor: pointer;

    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);

    background-color: ${colors.background_primary};
    border: 1px solid ${colors.border_200};

    font-size: 0.6875rem;
    font-family: ${fonts.primary};

    font-weight: 500;
    color: ${colors.text};
  }

  input[type="checkbox"]:checked + label {
    background-color: ${colors.title};
    color: ${colors.background_primary};
  }
`;

export const TableTitle = styled.h2`
  width: 76.24%;

  margin: 24px auto 12px;
  font-size: 1.125rem;
  font-family: ${fonts.primary};
  letter-spacing: 50%;
  font-weight: 600;
  color: ${colors.text};

  @media print {
    display: none;
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;

  width: 76.24%;

  height: 34.28%;
  min-height: 326px;

  margin: 0 auto;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  overflow: scroll;
  white-space: nowrap;

  position: relative;

  @media print {
    display: none;
  }
`;

export const TableHead = styled.div`
  display: flex;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: ${colors.background_primary};

  position: sticky;
  top: 0;
`;

export const TableBody = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  height: 68px;

  border-bottom: 1px solid ${colors.border_200};

  div.short {
    width: 122px;
  }
  div.medium {
    width: 180px;
  }
  div.end {
    width: 330px;
  }
`;

export const BodyRow = styled.button`
  display: flex;
  align-items: center;

  height: 68px;

  border: none;
  border-bottom: 1px solid ${colors.border_200};
  background-color: ${colors.background_primary};
  outline: none;

  div.short {
    width: 122px;
    justify-content: center;
  }

  div.medium {
    width: 180px;
    justify-content: center;
  }
  div.end {
    width: 330px;
  }
`;

export const TableHeadColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  padding: 0 16px;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.title};
    font-size: 0.875rem;
    font-family: ${fonts.primary};

    font-weight: 600;

    svg {
      margin-left: 15px;
    }
  }
`;

export const TableBodyColumn = styled.div`
  display: flex;
  align-items: center;

  padding: 0 25px;

  p {
    font-size: 0.875rem;
    font-family: ${fonts.primary};
    font-weight: 600;
    color: ${colors.text_detail};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  strong {
    font-size: 0.8125rem;
    font-family: ${fonts.secondary};
    font-weight: 600;
    color: ${colors.text_detail};
  }
`;

export const TableFooter = styled.div`
  color: ${colors.text};
  font-size: 0.875rem;
  font-family: ${fonts.primary};

  font-weight: 500;

  width: 76.24%;

  margin: 8px auto 80px;
`;

export const GraphContainer = styled.section`
  width: 76.24%;

  margin: 0px auto;
`;

export const GraphSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;

  gap: 20px 0;

  margin: 20px auto;
`;

export const MonthTable = styled.div`
  display: flex;
  flex-direction: column;

  width: 76.24%;

  height: 62.9%;
  min-height: 600px;

  margin: 0 auto;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  overflow: scroll;
  white-space: nowrap;

  position: relative;
`;
