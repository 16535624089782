import { Control, FieldValues, UseFormSetValue } from "react-hook-form";
import { ImageSection } from "../../../../Form/ImageSection";

import { Container, Section } from "./styles";

interface Props {
  control: Control;
  id: string;
  csid: string;
  mpid: string;
  cpno?: string;
  setValue?: UseFormSetValue<FieldValues>;
}

export function NecessaryDocument({
  control,
  id,
  csid,
  mpid,
  cpno,
  setValue,
}: Props) {
  return (
    <Container>
      <Section>
        <ImageSection
          title="クレジット契約書"
          name={`${id}credit_syo`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="creditsyo"
          up="creditsyo"
          setValue={setValue}
        />
        <ImageSection
          title="請負契約書"
          name={`${id}keiyaku_syo`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="keiyakusyo"
          up="keiyakusyo"
          setValue={setValue}
        />
        <ImageSection
          title="重要事項説明書"
          name={`${id}jyuyo_syo`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="jyuyosyo"
          up="jyuyosyo"
          setValue={setValue}
        />
        <ImageSection
          title="委任状"
          name={`${id}other01`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo01"
          up="othersyo01"
          setValue={setValue}
        />
        <ImageSection
          title="印鑑証明"
          name={`${id}other02`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo02"
          up="othersyo02"
          setValue={setValue}
        />
        <ImageSection
          title="謄本"
          name={`${id}other03`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo03"
          up="othersyo03"
          setValue={setValue}
        />
      </Section>
      <Section>
        <ImageSection
          title="電力申請"
          name={`${id}other04`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo04"
          up="othersyo04"
          setValue={setValue}
        />
        <ImageSection
          title="設備認定"
          name={`${id}other05`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo05"
          up="othersyo05"
          setValue={setValue}
        />

        <ImageSection
          title="保証書①"
          name={`${id}other06`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo06"
          up="othersyo06"
          setValue={setValue}
        />
        <ImageSection
          title="保証書②"
          name={`${id}other07`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo07"
          up="othersyo07"
          setValue={setValue}
        />
        <ImageSection
          title="現地調査依頼書"
          name={`${id}other08`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo08"
          up="othersyo08"
          setValue={setValue}
        />
        <ImageSection
          title="電気明細書"
          name={`${id}other09`}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          docid="othersyo09"
          up="othersyo09"
          setValue={setValue}
        />
      </Section>
    </Container>
  );
}
