import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 10px;

  z-index: 999;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60px;
  background-color: #333333;

  font-size: 1.125rem;
  font-family: ${fonts.primary};

  border-radius: 10px 10px 0 0;

  font-weight: 600;
  color: ${colors.background_primary};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 60px;
`;

export const Section = styled.section`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-bottom: 37px;
`;

export const ButtonSection = styled.div`
  display: flex;
  margin-top: auto;
  margin-left: auto;
`;

export const TitleContainer = styled.div`
  display: flex;

  p {
    font-size: 0.875rem;
    font-family: ${fonts.primary};

    font-weight: 600;
    color: ${colors.text_detail};
  }

  strong {
    font-size: 1.0625rem;
    font-family: ${fonts.primary};

    font-weight: 600;
    color: ${colors.title};

    margin-left: 35px;
  }

  margin-bottom: 35px;
`;

export const Table = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TableHead = styled.div`
  display: flex;

  border-bottom: 2px solid ${colors.border_200};
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

export const TableBodyRow = styled.div`
  display: flex;
`;

export const TableColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;

  padding: 8px 20px;

  font-size: 0.875rem;
  font-family: ${fonts.primary};

  font-weight: 600;
  color: ${colors.title};
`;

export const TableLastColumn = styled(TableColumn)`
  width: 240px;
`;
