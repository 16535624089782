import { statSync } from "fs";
import { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import api from "../../../../../services/api";
import { encodedParams } from "../../../../../utils/encodedParams";

import { InputSection } from "../../../Form/InputSection";
import { SelectSection } from "../../../Form/SelectSection";

import { Container, Section } from "./styles";

interface Props {
  control: Control;
}

interface StatusData {
  ststxt: string;
  stsval: number;
}

interface OptionProps {
  label: string;
  value: string;
}

export function ClientDetail({ control }: Props) {
  const [statusData, setStatusData] = useState<OptionProps[]>(
    [] as OptionProps[]
  );

  // 初期読み込み
  useEffect(() => {
    (async () => {
      try {
        // 非同期で顧客データ取得
        const data = {
          ukey: "trial",
          fid: 15,
        };

        const params = encodedParams(data);
        const response = await api.post("/", params);

        const parseData = response.data.data.csilst.map((data: StatusData) => {
          return {
            label: data.ststxt,
            value: data.stsval,
          };
        });

        setStatusData(parseData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Container>
      <Section>
        <SelectSection
          title="ステータス"
          options={statusData}
          control={control}
          name="sts"
        />
        <InputSection name="cnm" title="お客様名" control={control} />
        <InputSection name="ckana" title="お客様名カナ" control={control} />
      </Section>
      <Section>
        <InputSection name="ctel" title="電話番号" control={control} />

        <InputSection name="cpost" title="郵便番号" control={control} />
        <InputSection
          name="caddr"
          title="住所"
          control={control}
          sectionStyle={{ width: "30%", maxWidth: "500px" }}
        />
      </Section>
      <Section>
        <InputSection
          name="cmemo"
          title="備考"
          control={control}
          sectionStyle={{ minHeight: "200px", width: "42%", maxWidth: "630px" }}
          style={{
            minHeight: "200px",
          }}
        />
      </Section>
    </Container>
  );
}
