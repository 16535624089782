import { forwardRef, useRef } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import Input from "./Input";

registerLocale("ja", ja);

interface Props {
  date: Date;
  setDate: (date: Date) => void;
}

const CustomInput = forwardRef((props: any, ref) => {
  return <Input {...props} ref={ref} />;
});

export function YearSelect({ setDate, date }: Props) {
  const inputRef = useRef(null);

  return (
    <DatePicker
      locale="ja"
      dateFormat="yyyy"
      showYearPicker
      selected={date}
      customInput={<CustomInput inputRef={inputRef} />}
      onChange={(date: Date) => setDate(date)}
    />
  );
}
