import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 10px;

  overflow: scroll;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60px;
  background-color: #333333;

  font-size: 1.125 rem;
  font-family: ${fonts.primary};

  border-radius: 10px 10px 0 0;

  font-weight: 600;
  color: ${colors.background_primary};
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  padding: 60px;
`;
