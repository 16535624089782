import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 10px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60px;
  background-color: #333333;

  font-size: 1.125 rem;
  font-family: ${fonts.primary};

  border-radius: 10px 10px 0 0;

  font-weight: 600;
  color: ${colors.background_primary};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 60px;
`;

export const Section = styled.section`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-bottom: 37px;
`;

export const DeleteSection = styled.div`
  display: flex;
  border-radius: 3px;

  width: 20%;
  min-width: 250px;
  max-width: 300px;

  margin-right: 30px;

  font-size: 0.875rem;
  font-family: ${fonts.primary};

  color: ${colors.title};

  input[type="checkbox"]:checked + label {
    background-color: ${colors.main};
    color: ${colors.background_primary};

    border-radius: 3px;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    margin-left: 10px;

    height: 1.25rem;
    width: 1.25rem;

    border-radius: 3px;

    border: 1px solid ${colors.border_200};
    background-color: ${colors.background_primary};

    color: ${colors.background_primary};
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  margin-top: auto;
`;

export const DeleteButton = styled.button`
  width: 140px;

  height: 45px;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: ${colors.border_400};

  color: ${colors.background_primary};
  font-family: ${fonts.primary};

  font-size: 0.875rem;

  margin-left: 15px;
`;
