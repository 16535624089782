import { Control } from "react-hook-form";

import { InputSection } from "../../../Form/InputSection";

import { Container, Section } from "./styles";

interface Props {
  control: Control;
}

export function ClientFamily({ control }: Props) {
  return (
    <Container>
      <Section>
        <InputSection control={control} name="cpwc" title="電力会社" />
        <InputSection control={control} name="cpwn" title="電力受給No." />
      </Section>

      <Section>
        <InputSection control={control} name="chsag" title="築年数" unit="年" />
        <InputSection control={control} name="cdnkd" title="電気代" unit="円" />

        <InputSection control={control} name="cgusd" title="ガス代" unit="円" />
        <InputSection control={control} name="ctoud" title="灯油代" unit="円" />
      </Section>

      <Section>
        <InputSection control={control} name="cfami" title="家族構成" />
      </Section>
    </Container>
  );
}
