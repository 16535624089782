import styled from "styled-components";
import { colors, fonts } from "../../../../styles/theme";

export const Button = styled.button`
  width: 140px;

  height: 45px;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.main};

  border: none;
  color: ${colors.background_primary};
  font-family: ${fonts.primary};

  font-size: 0.875rem;

  margin-top: auto;
`;
