import { ForwardedRef, forwardRef } from "react";
import { FaSort } from "react-icons/fa";

import { Container, StyledInput } from "./styles";

interface InputProps {
  className: string;
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
}

function Input(
  { className, value, onClick, onChange }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <Container>
      <StyledInput
        className={className}
        type="text"
        value={`${value}日`}
        ref={ref}
        onChange={(e) => onChange(e.target.value)}
        onClick={onClick}
      />

      <FaSort size={14} color="#A2A6B1" />
    </Container>
  );
}

export default forwardRef(Input);
