import { HTMLAttributes } from "react";
import { StyledInput } from "./styles";

interface Props extends HTMLAttributes<HTMLInputElement> {
  value: string | undefined;
  setData: (value: string) => void;
}

export function Input({ value, setData, ...rest }: Props) {
  return (
    <StyledInput
      {...rest}
      type="text"
      value={value}
      onChange={(e) => setData(e.target.value)}
    />
  );
}
