import { useState } from "react";

import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

import { SelectDateContainer } from "../../components/Dashboard/SelectDateContainer";
import { ClientInformationModal } from "../../components/Modals/ClientInformationModal";
import { StaffChart } from "../../components/Dashboard/StaffChart";
import { StockChart } from "../../components/Dashboard/StockChart";
import { StockChartMini } from "../../components/Dashboard/StockChartMini";
import { GoalSettingModal } from "../../components/Modals/GoalSettingModal";

import {
  Container,
  TopFixContainer,
  Header,
  Title,
  FilterContainer,
  TodayProgressContainer,
  Progress,
  SelectInput,
  TableTitle,
  Table,
  TableHead,
  TableBody,
  TableRow,
  BodyRow,
  TableHeadColumn,
  TableBodyColumn,
  TableFooter,
  GraphContainer,
  GraphSection,
  MonthTable,
} from "./styles";

export function Dashboard() {
  const [modalIsOpen, setIsOpen] = useState(false);

  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [date, setDate] = useState<Date>(new Date(year, month - 1));

  const [filterType, setFilterType] = useState<Number>(1);

  function handleChangeFilterType(id: number) {
    setFilterType(id);
  }

  return (
    <Container>
      <TopFixContainer modalIsOpen={modalIsOpen}>
        <Header>
          <Title>DASHBOARD</Title>

          <SelectDateContainer
            date={date}
            setDate={setDate}
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear}
          />
          <FilterContainer>
            <SelectInput>
              <input
                id="1"
                type="checkbox"
                style={{ display: "none" }}
                checked={filterType === 1}
                onChange={() => handleChangeFilterType(1)}
              />
              <label htmlFor="1">年</label>
            </SelectInput>

            <SelectInput>
              <input
                id="2"
                type="checkbox"
                style={{ display: "none" }}
                checked={filterType === 2}
                onChange={() => handleChangeFilterType(2)}
              />
              <label htmlFor="2">月</label>
            </SelectInput>

            <SelectInput>
              <input
                id="3"
                type="checkbox"
                style={{ display: "none" }}
                checked={filterType === 3}
                onChange={() => handleChangeFilterType(3)}
              />
              <label htmlFor="3">日</label>
            </SelectInput>
          </FilterContainer>
        </Header>

        <TodayProgressContainer>
          <Progress>
            <p>訪問数</p>
            <strong>9,999</strong>
          </Progress>
          <Progress>
            <p>AP</p>
            <strong>9,999</strong>
          </Progress>
          <Progress>
            <p>再訪</p>
            <strong>9,999</strong>
          </Progress>
          <Progress>
            <p>契約</p>
            <strong>9,999</strong>
          </Progress>
        </TodayProgressContainer>
      </TopFixContainer>

      <TableTitle>社員名</TableTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadColumn className="medium">
              <p>
                名前
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>
                訪問数
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>
                AP
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>
                再訪
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>
                契約
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="medium">
              <p>
                売上合計(万円) <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="end" />
          </TableRow>
        </TableHead>
        <TableBody>
          <BodyRow onClick={() => setIsOpen(true)}>
            <TableBodyColumn className="medium">
              <p>高木 凌介</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="medium">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99</p>
            </TableBodyColumn>
            <TableBodyColumn className="end"></TableBodyColumn>
          </BodyRow>
        </TableBody>
      </Table>
      <TableFooter>
        *｢AP｣：訪問記録登録時に｢アポ｣にチェックの件数です。　*｢再訪｣：訪問記録登録時に｢興味あり・再訪｣のピンの件数です。　*｢契約｣：訪問記録登録時に｢契約獲得｣にチェックの件数です。
      </TableFooter>

      <GraphContainer>
        <GraphSection>
          <StaffChart />
          <StaffChart />
          <StaffChart />
          <StaffChart />
        </GraphSection>
        <GraphSection>
          <StockChart />
        </GraphSection>
        <GraphSection>
          <StockChartMini />
          <StockChartMini />
          <StockChartMini />
          <StockChartMini />
        </GraphSection>
      </GraphContainer>

      <MonthTable>
        <TableHead>
          <TableRow>
            <TableHeadColumn className="medium">
              <p>
                日付
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>
                訪問数
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>
                AP
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>
                再訪
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>
                契約
                <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="medium">
              <p>
                売上合計(万円) <FaSort size={14} />
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="end" />
          </TableRow>
        </TableHead>
        <TableBody>
          <BodyRow onClick={() => setIsOpen(true)}>
            <TableBodyColumn className="medium">
              <p>2021/12</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="short">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="medium">
              <p>99,999</p>
            </TableBodyColumn>
            <TableBodyColumn className="end"></TableBodyColumn>
          </BodyRow>
        </TableBody>
      </MonthTable>
      <TableFooter>
        *表示内容：｢年｣最大10年分、｢月｣最大3年分、｢日｣最大3ヵ月分を表示してます。
      </TableFooter>
      {modalIsOpen && (
        <GoalSettingModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      )}
    </Container>
  );
}
