import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { colors } from "../../styles/theme";

export const Container = styled.aside`
  width: 15.6%;
  min-width: 260px;
  max-width: 305px;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media print {
    display: none;
  }
`;

export const SidebarNav = styled.nav`
  width: calc(100% - 5px);
  height: 100%;

  background: ${colors.main};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ul {
    width: 100%;
    flex: 1;
    list-style: none;
  }
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;

  li {
    display: flex;

    width: 100%;
    flex: 1;
    padding: 21.5px 40px;

    font-size: 1rem;
    font-weight: 600;

    color: ${colors.background_primary};

    opacity: 0.7;

    p {
      margin-left: 30px;
    }
  }

  &[aria-current] {
    li {
      width: calc(100% + 5px);
      opacity: 1;

      background-color: ${colors.main_300};
      border: 2px solid ${colors.main_200};
    }
  }
`;
