import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const GraphContent = styled.div`
  width: 100%;
  max-height: 560px;

  background: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  padding: 60px;

  display: flex;
  justify-content: space-between;
`;

export const GraphDetail = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
`;

export const GraphTitle = styled.h3`
  font-size: 1.625rem;
  font-family: ${fonts.secondary};
  font-weight: 500;
  color: ${colors.text_detail};
`;

export const GraphStaffName = styled.strong`
  font-size: 2rem;
  font-family: ${fonts.primary};
  font-weight: 600;
  color: ${colors.text};

  margin: 7px 0 13px;
`;

export const GraphDetailDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 80px;
`;

export const GraphDetailData = styled.div`
  width: 25%;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 1.25rem;
    font-family: ${fonts.primary};
    font-weight: 600;
    color: ${colors.text_detail};
  }

  svg {
    margin-right: 12px;
  }

  margin-top: 14px;
`;

export const Graphs = styled.div`
  width: 75%;
  flex: 1;
  display: flex;
  position: relative;
`;
