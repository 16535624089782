import { ApexOptions } from "apexcharts";

export function option(color: string, label: string): ApexOptions {
  return {
    plotOptions: {
      radialBar: {
        track: {
          background: color,
          opacity: 0.4,
        },
        hollow: {
          size: "55%",
        },
        dataLabels: {
          name: {
            offsetY: -10,
            show: true,
            color: "#77838E",
            fontSize: "10px",
            fontWeight: 500,
          },
          value: {
            offsetY: -3,
            fontSize: "20px",
            color: "#77838E",
            fontWeight: 700,
            fontFamily: "Montserrat",
            show: true,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -20,
        left: -20,
        right: -20,
        bottom: -20,
      },
    },
    fill: {
      colors: [color],
    },
    stroke: {
      lineCap: "round",
      curve: "smooth",
    },
    labels: [label],
  };
}
