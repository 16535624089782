import styled from "styled-components";
import { colors, fonts } from "../../../../styles/theme";

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 4px 12px;

  font-size: 1rem;
  font-family: ${fonts.primary};
  color: ${colors.title};

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};
  border-radius: 3px;
`;

export const Unit = styled.p`
  font-size: 0.875rem;
  font-family: ${fonts.primary};
  color: ${colors.title};

  margin-left: 5px;
`;
