import { Control } from "react-hook-form";
import { InputSection } from "../../../../Form/InputSection";

import { Container, Section } from "./styles";

interface Props {
  control: Control;
  id: string;
}

export function Taiyoko({ control, id }: Props) {
  return (
    <Container>
      <Section>
        <InputSection
          title="メーカー"
          name={`${id}taiyokoMaker`}
          control={control}
        />

        <InputSection
          title="容量"
          name={`${id}taiyokoCapacity`}
          control={control}
        />

        <InputSection
          title="モジュール"
          name={`${id}taiyokoModule`}
          control={control}
        />
      </Section>

      <Section>
        <InputSection
          title="パワコン"
          name={`${id}powercon`}
          control={control}
        />

        <InputSection title="品番" name={`${id}taiyokoId`} control={control} />

        <InputSection
          title="有償保証"
          name={`${id}taiyokoWarranty`}
          control={control}
        />
      </Section>
    </Container>
  );
}
