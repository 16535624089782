import styled from "styled-components";
import { colors, fonts } from "../../../../../styles/theme";

export const StyledInput = styled.input`
  width: 20%;
  min-width: 250px;
  max-width: 300px;

  margin-right: 30px;

  display: flex;
  flex: 1;
  padding: 6px 16px;

  font-size: 1rem;
  font-family: ${fonts.primary};
  color: ${colors.title};

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};
  border-radius: 3px;
`;
