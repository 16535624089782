import { useEffect, useRef, useState } from "react";
import { ConfigDTO } from "../../../dtos/ConfigDTO";
import api from "../../../services/api";
import { encodedParams } from "../../../utils/encodedParams";

import { SelectBox } from "../../../components/Settings/SelectBox";

import {
  SettingContainer,
  Container,
  Header,
  StyledLink,
  Title,
  FilterContainer,
  Section,
  Content,
} from "./styles";

export function About() {
  const [hour, setHour] = useState();
  const [settings, setSetting] = useState<ConfigDTO[]>();

  const formRef = useRef<HTMLFormElement>(null);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      // params 内容
      const data = {
        ukey: "trial",
        fid: 1,
        type: 3,
        is_all: 0,
        is_basepin: 0,
      };
      const params = encodedParams(data);
      const response = await api.post("/", params);

      // 取得データから現設定内容をチェックボックスに反映
      // input取得
      const inputs = formRef.current?.querySelectorAll("input");

      inputs?.forEach((input) => {
        response.data.data.setting.forEach((d: ConfigDTO) => {
          if (input.id === d.title) {
            console.log(d.title);

            // Setting titleと同じ名前のInputを取得
            const filterInput: HTMLInputElement | null | undefined =
              formRef.current?.querySelector(`input[id=${d.title}]`);

            /// もしInputが入力且つ値が入ってる場合
            if (d.is_input === "1" && !!d.value) {
              if (filterInput) {
                filterInput.value = d.value;
              }
            }
          }
        });
      });

      setSetting(response.data.data);
    })();
  }, []);
  return (
    <SettingContainer>
      <Header>
        <Title>SETTINGS</Title>

        <FilterContainer>
          <StyledLink end to="/setting">
            アラート
          </StyledLink>
          <StyledLink to="/setting/record">記録</StyledLink>
          <StyledLink to="/setting/about">その他</StyledLink>
        </FilterContainer>
      </Header>

      <Container>
        <Section>
          <Content>
            現在地更新間隔
            <SelectBox setData={setHour} options={options} />
            分毎
          </Content>
        </Section>
      </Container>
    </SettingContainer>
  );
}

const options = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 22, label: "22" },
];
