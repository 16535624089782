import { Control } from "react-hook-form";
import { InputSection } from "../../../../Form/InputSection";

import { Container, Section } from "./styles";

interface Props {
  control: Control;
  id: string;
}

export function Ihcooking({ control, id }: Props) {
  return (
    <Container>
      <Section>
        <InputSection
          title="メーカー"
          name={`${id}ihMaker`}
          control={control}
        />
        <InputSection title="容量" name={`${id}ihCapacity`} control={control} />
        <InputSection
          title="有償保証"
          name={`${id}ihWarranty`}
          control={control}
        />
      </Section>
    </Container>
  );
}
