import { useState } from "react";
import {
  Control,
  FieldValues,
  UseFormResetField,
  UseFormSetValue,
} from "react-hook-form";

import { ClientContractHeader } from "../../Header/ClientContractHeader";

import { Basic } from "./Basic";
import { Ecocute } from "./Ecocute";
import { Ihcooking } from "./Ihcooking";
import { Progress } from "./Progress";
import { StorageBattery } from "./StorageBattery";
import { Taiyoko } from "./Taiyoko";
import { NecessaryDocument } from "./NecessaryDocument";

import { Container } from "./styles";

interface Props {
  control: Control;
  resetField: UseFormResetField<FieldValues>;
  id?: string;
  csid: string;
  mpid: string;
  cpno?: string;
  setValue?: UseFormSetValue<FieldValues>;
}
export function AddContract({
  control,
  resetField,
  id = "new",
  csid,
  mpid,
  cpno,
  setValue,
}: Props) {
  const [contractDetailHeader, setClientContractHeader] = useState<
    | "Basic"
    | "NecessaryDocument"
    | "Progress"
    | "Taiyoko"
    | "StorageBattery"
    | "Ecocute"
    | "Ihcooking"
  >("Basic");

  return (
    <Container>
      <ClientContractHeader
        value={contractDetailHeader}
        setHeader={setClientContractHeader}
      />

      {contractDetailHeader === "Basic" && (
        <Basic resetField={resetField} id={id} control={control} />
      )}
      {contractDetailHeader === "NecessaryDocument" && (
        <NecessaryDocument
          id={id}
          control={control}
          csid={csid}
          mpid={mpid}
          cpno={cpno}
          setValue={setValue}
        />
      )}

      {contractDetailHeader === "Progress" && (
        <Progress id={id} control={control} resetField={resetField} />
      )}
      {contractDetailHeader === "Taiyoko" && (
        <Taiyoko id={id} control={control} />
      )}
      {contractDetailHeader === "StorageBattery" && (
        <StorageBattery id={id} control={control} />
      )}
      {contractDetailHeader === "Ecocute" && (
        <Ecocute id={id} control={control} />
      )}
      {contractDetailHeader === "Ihcooking" && (
        <Ihcooking id={id} control={control} />
      )}
    </Container>
  );
}
