import { Container, MenuButton, MenuBorder } from "./styles";

interface Props {
  setNowHeader: (id: string) => void;
  nowHeader: string;
  currentContract: any[];
}

export function ClientDetailHeader({
  setNowHeader,
  nowHeader,
  currentContract,
}: Props) {
  function handleNavigate(id: string) {
    setNowHeader(id);
  }

  return (
    <Container>
      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("clientDetail")}
          isSelected={nowHeader === "clientDetail"}
        >
          顧客
        </MenuButton>
      </MenuBorder>

      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("clientFamily")}
          isSelected={nowHeader === "clientFamily"}
        >
          家庭
        </MenuButton>
      </MenuBorder>

      {currentContract.length > 0 &&
        currentContract.map((menu) => {
          const index = currentContract.findIndex((item) => item === menu) + 1;

          return (
            <MenuBorder key={index}>
              <MenuButton
                onClick={() => handleNavigate(`${index}contract`)}
                isSelected={nowHeader === `${index}contract`}
              >
                {index}
              </MenuButton>
            </MenuBorder>
          );
        })}

      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("addContract")}
          isSelected={nowHeader === "addContract"}
        >
          契約追加
        </MenuButton>
      </MenuBorder>
    </Container>
  );
}
