import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  display: flex;

  align-items: center;
`;

export const InputContainer = styled.div``;

export const SelectDayButton = styled.button`
  outline: none;
  border: none;

  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${colors.title};
  }
`;

export const SelectDayContainer = styled.div`
  display: flex;
`;

export const SelectDateContent = styled.div`
  width: 100px;
`;
