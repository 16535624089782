import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ImageSection } from "../Form/ImageSection";
import { InputSection } from "../Form/InputSection";
import { SaveButton } from "../Form/SaveButton";
import { StaffImageSection } from "../Form/StaffImageSection";

import { Input } from "./Input";

import { ReactModal } from "../ReactModal";

import {
  Container,
  Header,
  Content,
  Section,
  ButtonSection,
  TitleContainer,
  Table,
  TableHead,
  TableColumn,
  TableBody,
  TableBodyRow,
  TableLastColumn,
} from "./styles";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (data: boolean) => void;
}

interface FormData {
  name: string;
  amount: string;
}

export function GoalSettingModal({ modalIsOpen, setIsOpen }: Props) {
  const { control, handleSubmit, setValue, getValues, resetField } = useForm();

  useEffect(() => {
    const get = async () => {
      // 非同期的にデータをとってくる

      // setValueでRHFのformに値を入れる
      setValue("電力受給No", "123455");

      // setterを呼び出すことで再レンダリングが走る
    };
    get();
  }, []);

  // {
  //   resolver: yupResolver(schema),
  // }

  const contract = [1];

  const [nowHeader, setNowHeader] = useState("clientDetail");

  function handleSave(form: FormData) {
    setValue("電力受給No", "123456");

    console.log(form);
  }

  return (
    <ReactModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <h2>【2022年度 目標設定】</h2>
        </Header>

        <Content>
          <TitleContainer>
            <p>ユーザー名</p>
            <strong>愛知太郎</strong>
          </TitleContainer>

          <Table>
            <TableHead>
              <TableColumn>月</TableColumn>
              <TableColumn>訪問数</TableColumn>
              <TableColumn>アポ</TableColumn>
              <TableColumn>再訪問</TableColumn>
              <TableColumn>契約</TableColumn>
              <TableLastColumn>売上金額</TableLastColumn>
            </TableHead>
            <TableBody>
              <TableBodyRow>
                <TableColumn>4月</TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableLastColumn>
                  <Input control={control} name="houmon" unit="円" />
                </TableLastColumn>
              </TableBodyRow>
              <TableBodyRow>
                <TableColumn>5月</TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableLastColumn>
                  <Input control={control} name="houmon" unit="円" />
                </TableLastColumn>
              </TableBodyRow>
              <TableBodyRow>
                <TableColumn>6月</TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableColumn>
                  <Input control={control} name="houmon" unit="件" />
                </TableColumn>
                <TableLastColumn>
                  <Input control={control} name="houmon" unit="円" />
                </TableLastColumn>
              </TableBodyRow>
            </TableBody>
          </Table>
          <ButtonSection>
            <SaveButton onClick={handleSubmit(handleSave)} />
          </ButtonSection>
        </Content>
      </Container>
    </ReactModal>
  );
}
