import { createGlobalStyle } from "styled-components";
import { colors, fonts } from "./theme";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    scroll-behavior: smooth;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%; //15px;
    }
  }


  @media (max-width: 720px) {
    html {
      font-size: 87.5%; //14px;
    }
  }

  body {
    background: ${colors.background_secondary};
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: ${fonts.primary}, 'Meiryo',${fonts.secondary};
    font-size: 16px;
  }

  /* h1,h2,h3,h4,h5,h6, strong{
    font-weight: 500;
  } */

  button {
    cursor: pointer;
  }

  :-webkit-scrollbar {
    width: 4px;
  }

  /*スクロールバーの軌道*/
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);

  }

  /*スクロールバーの動く部分*/
  ::-webkit-scrollbar-thumb {
    background:${colors.title};
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
  }
`;
