import { ForwardedRef, forwardRef } from "react";

import { MdOutlineAccessTime, MdEventNote } from "react-icons/md";

import { Container, StyledInput } from "./styles";

interface InputProps {
  className: string;
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  icon?: "time" | "calendar";
}

function Input(
  { className, value, icon, onClick, onChange }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <Container>
      <StyledInput
        className={className}
        type="text"
        value={value}
        ref={ref}
        onChange={(e) => onChange(e.target.value)}
        onClick={onClick}
      />
      {icon && icon === "time" && <MdOutlineAccessTime size={20} />}
      {icon && icon === "calendar" && <MdEventNote size={20} />}
    </Container>
  );
}

export default forwardRef(Input);
