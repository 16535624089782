import { useEffect, useRef, useState } from "react";
import { CheckboxInput } from "../../components/Settings/CheckboxInput";
import { TextInput } from "../../components/Settings/TextInput";
import { ConfigDTO } from "../../dtos/ConfigDTO";
import api from "../../services/api";
import { encodedParams } from "../../utils/encodedParams";

import {
  SettingContainer,
  Container,
  Header,
  StyledLink,
  Title,
  FilterContainer,
  CheckboxSection,
  Section,
  CheckboxContent,
} from "./styles";

export function Setting() {
  const [settings, setSetting] = useState<ConfigDTO[]>();
  const [text, setText] = useState("");

  const formRef = useRef<HTMLFormElement>(null);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      // params 内容
      const data = {
        ukey: "trial",
        fid: 1,
        type: 1,
        is_all: 0,
        is_basepin: 0,
      };
      const params = encodedParams(data);
      const response = await api.post("/", params);

      // 取得データから現設定内容をチェックボックスに反映
      // input取得
      const inputs = formRef.current?.querySelectorAll("input");

      inputs?.forEach((input) => {
        response.data.data.setting.forEach((d: ConfigDTO) => {
          if (input.id === d.title) {
            // Setting titleと同じ名前のInputを取得
            const filterInput: HTMLInputElement | null | undefined =
              formRef.current?.querySelector(`input[id=${d.title}]`);

            /// もしInputが入力且つ値が入ってる場合
            if (d.is_input === "1" && !!d.value) {
              if (filterInput) {
                filterInput.value = d.value;
              }
            }

            // 有効であればチェック
            if (d.is_enabled === "1") {
              if (!filterInput) {
                return;
              }

              filterInput.checked = true;
            }
          }
        });
      });

      setSetting(response.data.data);
    })();
  }, []);

  // const handleSaveSetting(){

  // }

  return (
    <SettingContainer>
      <Header>
        <Title>SETTINGS</Title>

        <FilterContainer>
          <StyledLink end to="/setting">
            アラート
          </StyledLink>
          <StyledLink to="/setting/record">記録</StyledLink>
          <StyledLink to="/setting/about">その他</StyledLink>
        </FilterContainer>
      </Header>

      <Container ref={formRef}>
        <Section>
          <CheckboxInput
            id="アポ前日通知"
            title="アポ前日通知"
            containerStyle={{ marginRight: "100px" }}
          />

          <CheckboxInput id="卒Fit（９年目）通知" title="卒Fit（９年目）通知" />
        </Section>

        <Section>
          <CheckboxInput id="入居前通知" title="入居前通知" />

          <TextInput data={text} setData={setText} title="前回訪問日から" />
        </Section>

        <CheckboxSection>
          <Section style={{ marginBottom: "20px" }}>
            <CheckboxInput id="書類未提出通知" title="書類未提出通知" />

            <TextInput
              data={text}
              setData={setText}
              title="契約日から"
              unit="日後"
            />
          </Section>
          <CheckboxContent>
            <CheckboxInput id="請負契約書" title="請負契約書" />
          </CheckboxContent>
          <CheckboxContent>
            <CheckboxInput id="クレジット契約書" title="クレジット契約書" />
          </CheckboxContent>
          <CheckboxContent>
            <CheckboxInput id="重要事項説明書" title="重要事項説明書" />
          </CheckboxContent>
        </CheckboxSection>

        <CheckboxSection>
          <Section style={{ marginBottom: "20px" }}>
            <CheckboxInput id="進捗確認通知" title="進捗確認通知" />
          </Section>
          <CheckboxContent>
            <CheckboxInput
              id="現調日"
              title="現調日"
              containerStyle={{ width: "130px" }}
            />
            <TextInput data={text} setData={setText} unit="日後" />
          </CheckboxContent>
          <CheckboxContent>
            <CheckboxInput
              id="工事日①"
              title="工事日①"
              containerStyle={{ width: "130px" }}
            />
            <TextInput data={text} setData={setText} unit="日後" />
          </CheckboxContent>
          <CheckboxContent>
            <CheckboxInput
              id="工事日②"
              title="工事日②"
              containerStyle={{ width: "130px" }}
            />
            <TextInput data={text} setData={setText} unit="日後" />
          </CheckboxContent>
          <CheckboxContent>
            <CheckboxInput
              id="工事日③"
              title="工事日③"
              containerStyle={{ width: "130px" }}
            />
            <TextInput data={text} setData={setText} unit="日後" />
          </CheckboxContent>

          <CheckboxContent>
            <CheckboxInput
              id="完工日"
              title="完工日"
              containerStyle={{ width: "130px" }}
            />
            <TextInput data={text} setData={setText} unit="日後" />
          </CheckboxContent>

          <CheckboxContent>
            <CheckboxInput
              id="連系日"
              title="連系日"
              containerStyle={{ width: "130px" }}
            />
            <TextInput data={text} setData={setText} unit="日後" />
          </CheckboxContent>

          <CheckboxContent>
            <CheckboxInput
              id="保証申請完了日"
              title="保証申請完了日"
              containerStyle={{ width: "130px" }}
            />
            <TextInput data={text} setData={setText} unit="日後" />
          </CheckboxContent>
        </CheckboxSection>
      </Container>
    </SettingContainer>
  );
}
