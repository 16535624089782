import { useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormResetField,
} from "react-hook-form";
import { StaffDTO } from "../../../../../../dtos/StaffDTO";
import api from "../../../../../../services/api";
import { encodedParams } from "../../../../../../utils/encodedParams";
import { InputSection } from "../../../../Form/InputSection";
import { SelectDateSection } from "../../../../Form/SelectDateSection";
import { SelectSection } from "../../../../Form/SelectSection";

import { Container, Section, DeleteSection } from "./styles";

interface Props {
  control: Control;
  id: string;
  resetField: UseFormResetField<FieldValues>;
}

interface OptionProps {
  value: string;
  label: string;
}

export function Basic({ control, id, resetField }: Props) {
  const [staff, setStaff] = useState<OptionProps[]>([] as OptionProps[]);
  const paymentMethod = useMemo(() => {
    return [
      { label: "現金", value: "0" },
      { label: "クレジット（オリコ）", value: "1" },
      { label: "クレジット（アプラス）", value: "2" },
    ];
  }, []);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      // 社員一覧
      const staffData = {
        ukey: "trial",
        fid: 16,
      };
      const staffParams = encodedParams(staffData);
      const staffResponse = await api.post("/", staffParams);

      const parseStaff = staffResponse.data.data.usrlst.map(
        (data: StaffDTO) => {
          return {
            value: data.uid,
            label: data.unm,
          };
        }
      );

      setStaff(parseStaff); // スタッフリスト情報
    })();
  }, []);

  return (
    <Container>
      <Section>
        {id !== "new" && (
          <DeleteSection>
            <p>契約削除</p>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <input
                    type="checkbox"
                    id={`${id}deleteContract`}
                    onChange={onChange}
                    value={value}
                    style={{ display: "none" }}
                  />
                  <label htmlFor={`${id}deleteContract`}>&#10003;</label>
                </>
              )}
              name={`${id}deleteContract`}
            />
          </DeleteSection>
        )}

        <SelectDateSection
          resetField={resetField}
          title="契約日"
          name={`${id}condd`}
          control={control}
        />

        <SelectDateSection
          resetField={resetField}
          title="設置日"
          name={`${id}insdd`}
          control={control}
        />
      </Section>

      <Section>
        <InputSection
          title="契約金額"
          name={`${id}conval`}
          control={control}
          unit="円"
        />
        <InputSection
          title="粗利"
          name={`${id}conarari`}
          unit="円"
          control={control}
        />
      </Section>

      <Section>
        <SelectSection
          title="アポ担当"
          options={staff}
          control={control}
          name={`${id}ap_uid`}
        />
        <SelectSection
          title="契約担当"
          options={staff}
          control={control}
          name={`${id}con_uid`}
        />
        <SelectSection
          title="決済方法"
          options={paymentMethod}
          control={control}
          name={`${id}payment_method`}
        />
      </Section>

      <Section>
        <InputSection
          title="施工店"
          name={`${id}construction_shop`}
          control={control}
        />

        <SelectDateSection
          resetField={resetField}
          title="入金日"
          name={`${id}payment_day`}
          control={control}
        />
      </Section>
    </Container>
  );
}
