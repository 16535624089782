import { InputHTMLAttributes } from "react";
import { Control, Controller } from "react-hook-form";

import { InputContainer, StyledInput, Unit } from "./styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  control: Control;
  unit?: string;
  sectionStyle?: object;
}

export function Input({
  name,
  control,
  unit,
  sectionStyle = {},
  ...rest
}: Props) {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <InputContainer style={sectionStyle}>
          <StyledInput id={name} onChange={onChange} value={value} {...rest} />
          {unit && <Unit>{unit}</Unit>}
        </InputContainer>
      )}
      name={name}
    />
  );
}
