import { useCallback, useEffect, useState } from "react";

import { Input } from "../../components/Contract/Input";
import { StyledSelect } from "../../components/Contract/StyledSelect";
import { VisitInformationModal } from "../../components/Modals/VisitInformationModal";
import { SortIcon } from "../../components/SortIcon";

import { NewCustomerDTO, NewCustomerTotalDTO } from "../../dtos/NewCustomerDTO";
import { OpenModalDTO } from "../../dtos/OpenModalDTO";
import { SortDTO } from "../../dtos/SortDTO";
import { StaffDTO } from "../../dtos/StaffDTO";

import api from "../../services/api";
import { encodedParams } from "../../utils/encodedParams";
import { handleSetSort } from "../../utils/handleSetSort";

import {
  Container,
  Header,
  Title,
  TableFilter,
  FilterDetail,
  Table,
  TableHead,
  TableBody,
  TableRow,
  BodyRow,
  TableHeadColumn,
  TableBodyColumn,
  FilterContent,
  FilterBox,
} from "./styles";

interface OptionProps {
  label: string;
  value: string;
}

interface FormData {
  fid: number; // 処理ID
  ukey: string; // ユーザーKEY
  csno?: string; // 顧客No
  csnm?: string; // 顧客名
  addr?: string; // 住所
  uid?: number; // 社員（契約担当）
  sort?: number; // ソート順
  desc?: number; // ソート降順有無
}

export function NewCustomer() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMpid, setModalMpid] = useState("");
  const [modalCsid, setModalCsid] = useState("");

  const [sort, setSort] = useState<SortDTO>({
    desc: 1,
    sort: 0,
  });

  const [filterStaff, setFilterStaff] = useState<string | undefined>(); // フィルター社員
  const [staffOptions, setStaffOptions] = useState<OptionProps[]>(
    [] as OptionProps[]
  );

  const [filterClientNo, setFilterClientNo] = useState<string | undefined>(); // フィルター顧客No.
  const [filterClientName, setFilterClientName] = useState<
    string | undefined
  >(); // フィルター顧客名
  const [filterClientAddress, setFilterClientAddress] = useState<
    string | undefined
  >(); // フィルター住所

  const [newCustomerList, setNewCustomerList] = useState<NewCustomerDTO[]>();
  const [total, setTotal] = useState<NewCustomerTotalDTO>();

  const load = useCallback(async () => {
    try {
      let data: FormData = {
        ukey: "trial",
        fid: 30,
        desc: sort.desc,
        sort: sort.sort,
      };

      // フィルター社員で選択されていればPOST通信と一緒に送信
      if (filterStaff) {
        data = {
          ...data,
          uid: Number(filterStaff),
        };
      }
      // フィルター顧客Noが入力されていればPOST通信と一緒に送信
      if (filterClientNo) {
        data = {
          ...data,
          csno: filterClientNo,
        };
      }
      // フィルター顧客名が入力されていればPOST通信と一緒に送信
      if (filterClientName) {
        data = {
          ...data,
          csnm: filterClientName,
        };
      }
      // フィルター住所が入力されていればPOST通信と一緒に送信
      if (filterClientAddress) {
        data = {
          ...data,
          addr: filterClientAddress,
        };
      }

      const params = encodedParams(data);
      const response = await api.post("/", params);

      setNewCustomerList(response.data.data.cslst);
      setTotal(response.data.data.cntlst);
    } catch (error) {
      console.log(error);
    }
  }, [
    filterClientAddress,
    filterClientName,

    filterClientNo,
    filterStaff,
    sort,
  ]);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      // params 内容
      const staffData = {
        ukey: "trial",
        fid: 16,
      };
      const staffParams = encodedParams(staffData);
      const staffResponse = await api.post("/", staffParams);

      const parseData = staffResponse.data.data.usrlst.map((d: StaffDTO) => {
        return {
          label: d.unm,
          value: d.uid,
        };
      });

      load();
      setStaffOptions(parseData);
    })();
  }, [load]);

  // モーダル開
  function handleOpenModal({ mpid, csid }: OpenModalDTO) {
    setModalMpid(String(mpid));
    setModalCsid(String(csid));
    setIsOpen(true);
  }

  return (
    <Container>
      <Header>
        <Title>NEW CUSTOMER</Title>
      </Header>

      <TableFilter>
        <FilterDetail>
          全{total?.total}件（契約:{total?.contract}件／見込:{total?.prospect}
          件）
        </FilterDetail>

        <FilterContent>
          <FilterBox>
            <h3>顧客No.</h3>
            <Input value={filterClientNo} setData={setFilterClientNo} />
          </FilterBox>
          <FilterBox className="short">
            <h3>顧客名</h3>
            <Input value={filterClientName} setData={setFilterClientName} />
          </FilterBox>
          <FilterBox className="long">
            <h3>住所</h3>
            <Input
              value={filterClientAddress}
              setData={setFilterClientAddress}
            />
          </FilterBox>
          <FilterBox>
            <h3>社員(契約担当)</h3>
            <StyledSelect setData={setFilterStaff} options={staffOptions} />
          </FilterBox>
        </FilterContent>
      </TableFilter>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 1,
                })
              }
              className="short"
            >
              <p>
                ステータス
                {SortIcon({ sort, sortNumber: 1 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 2,
                })
              }
              className="medium"
            >
              <p>
                顧客名
                {SortIcon({ sort, sortNumber: 2 })}
              </p>
            </TableHeadColumn>

            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 3,
                })
              }
              className="long"
            >
              <p>
                住所
                {SortIcon({ sort, sortNumber: 3 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 4,
                })
              }
              className="medium"
            >
              <p>
                電話番号
                {SortIcon({ sort, sortNumber: 4 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 5,
                })
              }
              className="medium"
            >
              <p>
                最終訪問日
                {SortIcon({ sort, sortNumber: 5 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 6,
                })
              }
              className="medium"
            >
              <p>
                顧客感度
                {SortIcon({ sort, sortNumber: 6 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 0,
                })
              }
              className="medium"
            >
              <p>
                最終契約日
                {SortIcon({ sort, sortNumber: 0 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 8,
                })
              }
              className="extra-large"
            >
              <p>
                備考
                {SortIcon({ sort, sortNumber: 8 })}
              </p>
            </TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {newCustomerList &&
            newCustomerList.map((item) => (
              <BodyRow
                key={String(item.mpid) + String(item.csid) + item.csnm}
                onClick={() =>
                  handleOpenModal({ mpid: item.mpid, csid: item.csid })
                }
              >
                <TableBodyColumn className="short">
                  <p>{item.sts}</p>
                </TableBodyColumn>
                <TableBodyColumn className="medium">
                  <p>{item.csnm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="long">
                  <p>{item.addr}</p>
                </TableBodyColumn>
                <TableBodyColumn className="medium">
                  <p>{item.telno}</p>
                </TableBodyColumn>
                <TableBodyColumn className="medium">
                  <p>{item.vidt}</p>
                </TableBodyColumn>
                <TableBodyColumn className="medium">
                  <p>{item.pnm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="medium">
                  <p>{item.condd}</p>
                </TableBodyColumn>
                <TableBodyColumn className="extra-large">
                  <p>{item.memo}</p>
                </TableBodyColumn>
              </BodyRow>
            ))}
        </TableBody>
      </Table>

      <VisitInformationModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        mpid={modalMpid}
        csid={modalCsid}
      />
    </Container>
  );
}
