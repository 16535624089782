import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 10px;

  overflow: scroll;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60px;
  background-color: #333333;

  font-size: 1.125 rem;
  font-family: ${fonts.primary};

  border-radius: 10px 10px 0 0;

  font-weight: 600;
  color: ${colors.background_primary};
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  padding: 60px;
`;

export const Section = styled.section`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-bottom: 37px;
`;

export const SelectInput = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;

  cursor: pointer;

  label {
    cursor: pointer;

    min-width: 130px;
    padding: 9px 25px;

    border-radius: 45px;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);

    background-color: ${colors.background_primary};
    border: 1px solid ${colors.border_200};

    text-align: center;
    font-size: 0.6875rem;
    font-family: ${fonts.primary};

    font-weight: 500;
    color: ${colors.text};
  }

  input[type="checkbox"]:checked + label {
    background-color: ${colors.title};
    color: ${colors.background_primary};
  }
`;
