import { HTMLAttributes } from "react";

import { Button } from "./styles";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
}

export function SaveButton({ onClick, ...rest }: Props) {
  return (
    <Button {...rest} onClick={onClick}>
      保存
    </Button>
  );
}
