import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { InputSection } from "../Form/InputSection";
import { SaveButton } from "../Form/SaveButton";
import { SelectDateSection } from "../Form/SelectDateSection";
import { SelectTimeSection } from "../Form/SelectTimeSection";

import { ReactModal } from "../ReactModal";

import api from "../../../services/api";
import { encodedParams } from "../../../utils/encodedParams";
import { parseStringToDate } from "../../../utils/parseStringToDate";

import { PinTypeDTO } from "../../../dtos/PinTypeDTO";
import { RegistredPinInfoDTO } from "../../../dtos/RegistredPinInfoDTO";

import { Container, Header, Content, Section, SelectInput } from "./styles";
import { parseStringToTime } from "../../../utils/parseStringToTime";
import { TextareaSection } from "../Form/TextareaSection";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (data: boolean) => void;
  mpid: string;
  csid: string;
}

interface FormData {
  mpid: string; // マップピンID
  csid: string; // 顧客ID
  lat: string; // 緯度
  lng: string; // 経度
  addr: string; // 住所
  pno: string; //	顧客感度(ピンID)
  prd: any[];
  vidt: string; //	訪問日
  vitm: string; //	訪問時刻
  appdt: string; //	アポ日
  oppdt: string; //	商談日
  lbnm: string; //	ラベル名
  photo_house: string; //	家写真URL
  csnm: string; //	顧客名
  memo: string; //	メモ
}

export function VisitInformationModal({
  modalIsOpen,
  setIsOpen,
  mpid,
  csid,
}: Props) {
  console.log("mpid,csid", mpid, csid);

  const { control, handleSubmit, setValue, getValues, resetField } = useForm();
  const [filterType, setFilterType] = useState<string | null>();
  const [pinTypes, setPinTypes] = useState<PinTypeDTO[]>();

  const [clientDetail, setClientDetail] = useState<RegistredPinInfoDTO>();

  const [loading, setLoading] = useState(true);

  const setValueInInput = useCallback(
    (data: RegistredPinInfoDTO) => {
      const vidt = parseStringToDate(data.vidt);
      const appdt = parseStringToDate(data.appdt);
      const oppdt = parseStringToDate(data.oppdt);
      const vitm = parseStringToTime(data.vitm);

      setValue("vidt", vidt);
      setValue("vitm", vitm);
      setValue("addr", data.addr);
      setValue("appdt", appdt);
      setValue("oppdt", oppdt);
      setValue("csnm", data.csnm);
      setValue("memo", data.memo);

      setFilterType(String(data.pno));
    },
    [setValue]
  );

  // api初期読み込み
  useEffect(() => {
    (async () => {
      setLoading(true);
      // params 内容
      const data = {
        ukey: "trial",
        fid: 7,
        mpid,
        csid,
      };
      const params = encodedParams(data);
      const response = await api.post("/", params);

      const pinData = {
        fid: 11,
        ukey: "trial",
      };
      const pinParams = encodedParams(pinData);
      const pinResponse = await api.post("/", pinParams);

      setPinTypes(pinResponse.data.data.pinlst);

      // 顧客情報の保管
      setClientDetail(response.data.data);

      // 現在入力されている分をセット
      setValueInInput(response.data.data);

      setLoading(false);
    })();
  }, [csid, mpid]);

  function handleChangeFilterType(id: number) {
    if (filterType === String(id)) {
      return setFilterType(null);
    }

    setFilterType(String(id));
  }

  // 情報保存
  async function handleSave(form: FormData) {
    try {
      console.log(form);
      // 既存登録の場合
      // const cpno = getValues("newcpno");
      // const condd = getValues("newcondd");
      // const insdd = getValues("newinsdd");
      // const conval = getValues("newconval");
      // const conarari = getValues("newconarari");
      // const apo = getValues("newap_uid");
      // const con = getValues("newcon_uid");
      // const pay = getValues("newpayment_method");
      // const construction_shop = getValues("newconstruction_shop");
      // const payment_day = getValues("newpayment_day");
      // const ap_uid = String(staff?.find((s) => s.unm === apo)?.uid);
      // const con_uid = String(staff?.find((s) => s.unm === con)?.uid);

      // const data = {
      //   ukey: "trial",
      //   fid: 18,
      //   mpid: mpid,
      //   csid: csid,
      // };

      // const sts = statusData?.find((s) => s.ststxt === form.sts)?.stsval;
      // const csilst = {
      //   sts,
      //   cnm: form.cnm,
      //   ckana: form.ckana,
      //   ctel: form.ctel,
      //   cpost: form.cpost,
      //   caddr: form.caddr,
      //   cmemo: form.cmemo,
      // };
      // const famlst = {
      //   cpwc: form.cpwc,
      //   cpwn: form.cpwn,
      //   chsag: form.chsag,
      //   cdnkd: form.cdnkd,
      //   cgusd: form.cgusd,
      //   ctoud: form.ctoud,
      //   cfami: form.cfami,
      // };

      // const params = encodedParams(data);

      // await api.post("/", params);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ReactModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <h2>【訪問情報】</h2>
        </Header>

        {loading ? (
          <p>...Load</p>
        ) : (
          <Content>
            <Section>
              <SelectDateSection
                resetField={resetField}
                title="訪問日"
                name="vidt"
                control={control}
              />
              <SelectTimeSection
                resetField={resetField}
                title="訪問時刻"
                name="vitm"
                control={control}
              />
              <InputSection
                title="顧客名"
                name="csnm"
                control={control}
                readOnly
              />
              <InputSection
                title="住所"
                name="addr"
                control={control}
                readOnly
              />
            </Section>
            <Section>
              {pinTypes &&
                pinTypes.map((item) => (
                  <SelectInput>
                    <input
                      id={String(item.pno)}
                      type="checkbox"
                      style={{ display: "none" }}
                      checked={filterType === String(item.pno)}
                      onChange={() => handleChangeFilterType(item.pno)}
                    />
                    <label htmlFor={String(item.pno)}>{item.pnm}</label>
                  </SelectInput>
                ))}
            </Section>
            <Section>
              <SelectDateSection
                resetField={resetField}
                title="アポ日"
                name="appdt"
                control={control}
              />
              <SelectDateSection
                resetField={resetField}
                title="商談日"
                name="oppdt"
                control={control}
              />
            </Section>
            <Section>
              <TextareaSection
                name="memo"
                title="メモ"
                control={control}
                style={{
                  minHeight: "200px",
                }}
              />
            </Section>

            <SaveButton onClick={handleSubmit(handleSave)} />
          </Content>
        )}
      </Container>
    </ReactModal>
  );
}
