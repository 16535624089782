import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  width: 100%;
  padding: 15px 20px;

  display: flex;
  align-items: center;

  justify-content: space-between;

  svg {
    color: ${colors.title};
    /* width: 20px; */
    /* height: 20px; */
  }

  background: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;
`;

export const StyledInput = styled.input`
  width: 100%;
  flex: 1;
  border: none;
  outline: none;
  padding-right: 20px;

  color: ${colors.text_detail};

  font-size: 0.9375rem;
  font-family: ${fonts.secondary};

  font-weight: 500;
`;
