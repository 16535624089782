import { Header, MenuButton, MenuBorder } from "./styles";

interface Props {
  value:
    | "Basic"
    | "NecessaryDocument"
    | "Progress"
    | "Taiyoko"
    | "StorageBattery"
    | "Ecocute"
    | "Ihcooking";
  setHeader: (
    headerName:
      | "Basic"
      | "NecessaryDocument"
      | "Progress"
      | "Taiyoko"
      | "StorageBattery"
      | "Ecocute"
      | "Ihcooking"
  ) => void;
}

export function ClientContractHeader({ value, setHeader }: Props) {
  function handleNavigate(
    id:
      | "Basic"
      | "NecessaryDocument"
      | "Progress"
      | "Taiyoko"
      | "StorageBattery"
      | "Ecocute"
      | "Ihcooking"
  ) {
    setHeader(id);
  }

  return (
    <Header>
      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("Basic")}
          isSelected={value === "Basic"}
        >
          基本
        </MenuButton>
      </MenuBorder>

      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("NecessaryDocument")}
          isSelected={value === "NecessaryDocument"}
        >
          必要書類
        </MenuButton>
      </MenuBorder>

      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("Progress")}
          isSelected={value === "Progress"}
        >
          進捗
        </MenuButton>
      </MenuBorder>

      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("Taiyoko")}
          isSelected={value === "Taiyoko"}
        >
          太陽光
        </MenuButton>
      </MenuBorder>

      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("StorageBattery")}
          isSelected={value === "StorageBattery"}
        >
          蓄電池
        </MenuButton>
      </MenuBorder>

      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("Ecocute")}
          isSelected={value === "Ecocute"}
        >
          エコキュート
        </MenuButton>
      </MenuBorder>

      <MenuBorder>
        <MenuButton
          onClick={() => handleNavigate("Ihcooking")}
          isSelected={value === "Ihcooking"}
        >
          IHクッキング
        </MenuButton>
      </MenuBorder>
    </Header>
  );
}
