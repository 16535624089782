import { useMemo } from "react";
import Chart from "react-apexcharts";
import { MdCircle } from "react-icons/md";

import { useWindowDimensions } from "../../../hooks/useWindowDimension";

import { option } from "./option";
import { colors } from "../../../styles/theme";

import {
  GraphContent,
  GraphDetail,
  GraphTitle,
  GraphStaffName,
  GraphDetailDataContainer,
  GraphDetailData,
  Graphs,
} from "./styles";

export function StockChart() {
  const { width } = useWindowDimensions();

  const chart_1_options = option();

  const lineData = [
    20, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16, 1, 0, 12, 13, 12, 3, 12, 22,
    12, 16, 1, 0, 12, 13, 12, 3, 12, 0, 0,
  ];

  let tempData = 0;
  const columnData = [...lineData.map((d) => (tempData += d))];

  const series = [
    {
      name: "累計訪問数",
      type: "column",
      data: columnData,
    },
    {
      name: "訪問数",
      type: "line",
      data: lineData,
    },
  ];

  const parseWidth = useMemo(() => {
    const containetWidth = width * 0.7624;

    if (containetWidth <= 1460) {
      return containetWidth - 480;
    }

    return 1000;
  }, [width]);

  return (
    <GraphContent id="stockchart">
      <GraphDetail>
        <GraphTitle>Item</GraphTitle>
        <GraphStaffName>訪問数</GraphStaffName>

        <GraphDetailDataContainer>
          <GraphDetailData>
            <p>
              <MdCircle size={10} color={colors.chart_2} />
              訪問数
            </p>
          </GraphDetailData>
          <GraphDetailData>
            <p>
              <MdCircle size={10} color={colors.chart_1} />
              累計訪問数
            </p>
          </GraphDetailData>
        </GraphDetailDataContainer>
      </GraphDetail>
      <Graphs>
        <Chart
          options={chart_1_options}
          series={series}
          type="area"
          height={380}
          width={parseWidth}
        />
      </Graphs>
    </GraphContent>
  );
}
