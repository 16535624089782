import { useCallback, useEffect, useState } from "react";
import { ClientInformationModal } from "../../components/Modals/ClientInformationModal";

import { SelectDate } from "../../components/SelectDate";
import { StyledSelect } from "../../components/StyledSelect";
import api from "../../services/api";
import { encodedParams } from "../../utils/encodedParams";

import { StaffDTO } from "../../dtos/StaffDTO";
import { TimelineDTO } from "../../dtos/TimelineDTO";
import {
  Container,
  Header,
  Title,
  FilterContainer,
  FilterBox,
  TodayProgressContainer,
  Border,
  TodayProgressContent,
  Progress,
  TableFilter,
  SelectInput,
  Table,
  TableHead,
  TableBody,
  TableRow,
  BodyRow,
  TableHeadColumn,
  TableBodyColumn,
  TableFooter,
} from "./styles";
import { format } from "date-fns";
import { DailyReportTotalDTO } from "../../dtos/DailyReportDTO";
import { SortIcon } from "../../components/SortIcon";
import { handleSetSort } from "../../utils/handleSetSort";
import { OpenModalDTO } from "../../dtos/OpenModalDTO";

interface OptionProps {
  label: string;
  value: string;
}

interface FormData {
  fid: number; // 処理ID
  ukey: string; // ユーザーKEY
  dd?: string; // 日付
  tm?: string; // 時刻
  uid?: number; // 社員ID
  pno?: number; // 顧客感度
  sort?: number; // ソート順
  desc?: number; // ソート降順有無
}

interface SortProps {
  desc: number; // 0:昇順／1･未設定:降順
  sort: number; //0･未設定:日時／1:社員／2:顧客名／3:住所／4:追客状況／5:アポ／6:商談／7:契約／8～N:現況(xxxx)
}

export function Timeline() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMpid, setModalMpid] = useState("");
  const [modalCsid, setModalCsid] = useState("");

  const [staffOptions, setStaffOptions] = useState<OptionProps[]>(
    [] as OptionProps[]
  );
  const [timelineList, setTimelineList] = useState<TimelineDTO[]>(); // タイムライン表内容
  const [total, setTotal] = useState<DailyReportTotalDTO>();

  const [filterDate, setFilterDate] = useState<Date | null>(null); // フィルター日付
  const [filterHour, setFilterHour] = useState<Date | null>(null); // フィルター時間

  const [filterType, setFilterType] = useState<number | undefined>(); // フィルター感度

  const [filterStaff, setFilterStaff] = useState<string | undefined>(); // フィルター社員
  const [sort, setSort] = useState<SortProps>({
    desc: 1,
    sort: 0,
  });

  function handleChangeFilterType(id: number) {
    if (filterType === id) {
      return setFilterType(undefined);
    }

    setFilterType(id);
  }

  const load = useCallback(async () => {
    try {
      let dd = format(new Date(), "yyyy-MM-dd");
      if (filterDate != null) {
        dd = format(filterDate, "yyyy-MM-dd");
      }

      let tm = format(new Date(), "HH:mm");
      if (filterHour != null) {
        tm = format(filterHour, "HH:mm");
      }

      let data: FormData = {
        ukey: "trial",
        fid: 26,
        dd,
        tm,
        desc: sort.desc,
        sort: sort.sort,
      };

      if (filterStaff) {
        data = {
          ...data,
          uid: Number(filterStaff),
        };
      }

      if (filterType) {
        data = {
          ...data,
          pno: filterType,
        };
      }

      const params = encodedParams(data);
      const response = await api.post("/", params);

      setTimelineList(response.data.data.tmllst);
    } catch (error) {
      console.log(error);
    }
  }, [filterDate, filterHour, filterStaff, filterType, sort]);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      // params 内容
      const staffData = {
        ukey: "trial",
        fid: 16,
      };
      const staffParams = encodedParams(staffData);
      const staffResponse = await api.post("/", staffParams);

      const parseData = staffResponse.data.data.usrlst.map((d: StaffDTO) => {
        return {
          label: d.unm,
          value: d.uid,
        };
      });

      const todayTotalData = {
        ukey: "trial",
        fid: 27,
        y: new Date().getFullYear(),
        m: new Date().getMonth() + 1,
        d: new Date().getDate(),
      };

      const todayTotalParams = encodedParams(todayTotalData);
      const todayTotalResponse = await api.post("/", todayTotalParams);

      load();
      setStaffOptions(parseData);
      setTotal(todayTotalResponse.data.data.total);
    })();
  }, [load]);

  function handleOpenModal({ mpid, csid }: OpenModalDTO) {
    setModalMpid(String(mpid));
    setModalCsid(String(csid));
    setIsOpen(true);
  }

  return (
    <Container>
      <Header>
        <Title>TIMELINE</Title>

        <FilterContainer>
          <FilterBox>
            <SelectDate
              date={filterDate}
              setDate={setFilterDate}
              icon="calendar"
            />
          </FilterBox>

          <FilterBox>
            <SelectDate
              date={filterHour}
              setDate={setFilterHour}
              icon="time"
              timeOnly
            />
          </FilterBox>

          <FilterBox>
            <StyledSelect setData={setFilterStaff} options={staffOptions} />
          </FilterBox>
        </FilterContainer>
      </Header>

      <TodayProgressContainer>
        <h3>【本日の進捗状況】</h3>
        <Border />
        <TodayProgressContent>
          <Progress>
            <p>訪問数</p>
            <strong>{total && total.vcnt ? total.vcnt : "-"}</strong>
          </Progress>
          <Progress>
            <p>AP</p>
            <strong>{total && total.ncnt ? total.ncnt : "-"}</strong>
          </Progress>
          <Progress>
            <p>再訪</p>
            <strong>{total && total.rcnt ? total.rcnt : "-"}</strong>
          </Progress>
          <Progress>
            <p>契約</p>
            <strong>{total && total.ccnt ? total.ccnt : "-"}</strong>
          </Progress>
        </TodayProgressContent>
      </TodayProgressContainer>

      <TableFilter>
        <SelectInput>
          <input
            id="1"
            type="checkbox"
            style={{ display: "none" }}
            checked={filterType === 1}
            onChange={() => handleChangeFilterType(1)}
          />
          <label htmlFor="1">興味あり・再訪問</label>
        </SelectInput>

        <SelectInput>
          <input
            id="2"
            type="checkbox"
            style={{ display: "none" }}
            checked={filterType === 2}
            onChange={() => handleChangeFilterType(2)}
          />
          <label htmlFor="2">不在</label>
        </SelectInput>

        <SelectInput>
          <input
            id="3"
            type="checkbox"
            style={{ display: "none" }}
            checked={filterType === 3}
            onChange={() => handleChangeFilterType(3)}
          />
          <label htmlFor="3">興味なし・NG</label>
        </SelectInput>

        <SelectInput>
          <input
            id="4"
            type="checkbox"
            style={{ display: "none" }}
            checked={filterType === 4}
            onChange={() => handleChangeFilterType(4)}
          />
          <label htmlFor="4">未入居</label>
        </SelectInput>

        <SelectInput>
          <input
            id="5"
            type="checkbox"
            style={{ display: "none" }}
            checked={filterType === 5}
            onChange={() => handleChangeFilterType(5)}
          />
          <label htmlFor="5">無効</label>
        </SelectInput>
      </TableFilter>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadColumn
              className="medium"
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 0,
                })
              }
            >
              <p>
                日時
                {SortIcon({ sort, sortNumber: 0 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 1,
                })
              }
              className="short"
            >
              <p>
                社員
                {SortIcon({ sort, sortNumber: 1 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 2,
                })
              }
              className="short"
            >
              <p>
                顧客名
                {SortIcon({ sort, sortNumber: 2 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 3,
                })
              }
              className="long"
            >
              <p>
                住所
                {SortIcon({ sort, sortNumber: 3 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 4,
                })
              }
              className="short"
            >
              <p>
                顧客感度
                {SortIcon({ sort, sortNumber: 4 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 8,
                })
              }
              className="short-medium"
            >
              <p>
                現況（太陽光）
                {SortIcon({ sort, sortNumber: 8 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 9,
                })
              }
              className="short"
            >
              <p>
                現況（EQ）
                {SortIcon({ sort, sortNumber: 9 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 5,
                })
              }
              className="medium"
            >
              <p>
                アポ
                {SortIcon({ sort, sortNumber: 5 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 6,
                })
              }
              className="medium"
            >
              <p>
                商談日
                {SortIcon({ sort, sortNumber: 6 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn
              onClick={() =>
                handleSetSort({
                  sort,
                  setSort,
                  sortNumber: 7,
                })
              }
              className="short-medium"
            >
              <p>
                契約
                {SortIcon({ sort, sortNumber: 7 })}
              </p>
            </TableHeadColumn>
            <TableHeadColumn className="extra-large">
              <p>訪問内容</p>
            </TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {timelineList &&
            timelineList.map((data) => {
              return (
                <BodyRow
                  key={String(data.mpid) + String(data.csid)}
                  onClick={() =>
                    handleOpenModal({ mpid: data.mpid, csid: data.csid })
                  }
                >
                  <TableBodyColumn className="medium">
                    <strong>{data.dt}</strong>
                  </TableBodyColumn>
                  <TableBodyColumn className="short">
                    <p>{data.unm}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short">
                    <p>{data.csnm}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="long">
                    <p>{data.addr}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short">
                    <p>{data.pnm}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{data.ins.find((d) => d.pnm === "太陽光")?.insyy}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short">
                    <p>
                      {data.ins.find((d) => d.pnm === "エコキュート")?.insyy}
                    </p>
                  </TableBodyColumn>
                  <TableBodyColumn className="medium">
                    <p>{data.apdd}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="medium">
                    <p>{data.oppdd}</p>
                  </TableBodyColumn>
                  <TableBodyColumn className="short-medium">
                    <p>{data.is_con}</p>
                  </TableBodyColumn>

                  <TableBodyColumn className="extra-large">
                    <p>{data.memo}</p>
                  </TableBodyColumn>
                </BodyRow>
              );
            })}
        </TableBody>
      </Table>
      <TableFooter>（先頭1,000件のみ表示）</TableFooter>
      {modalIsOpen && (
        <ClientInformationModal
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          mpid={modalMpid}
          csid={modalCsid}
        />
      )}
    </Container>
  );
}
