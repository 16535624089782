import { ReactNode } from "react";
import "./styles.css";

import Modal from "react-modal";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (data: boolean) => void;
  children?: ReactNode;
}
export function ReactModal({ modalIsOpen, setIsOpen, children }: Props) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      closeTimeoutMS={500}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after",
        beforeClose: "content-before",
      }}
    >
      {children}
    </Modal>
  );
}
