import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ClientDetailDTO,
  ContractListDTO,
} from "../../../dtos/ClientDetailDTO";
import { OptionDTO } from "../../../dtos/OptionDTO";
import { StaffDTO } from "../../../dtos/StaffDTO";
import api from "../../../services/api";
import { encodedParams } from "../../../utils/encodedParams";
import { parseStringToDate } from "../../../utils/parseStringToDate";
import { SaveButton } from "../Form/SaveButton";

import { ReactModal } from "../ReactModal";
import { AddContract } from "./Content/AddContract";
import { ClientDetail } from "./Content/ClientDetail";
import { ClientFamily } from "./Content/ClientFamilly";
import { ClientDetailHeader } from "./Header/ClientDetailHeader";

import { Container, Header, Content } from "./styles";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (data: boolean) => void;
  mpid: string;
  csid: string;
}

interface FormData {
  sts: OptionDTO; // ステータス値
  cnm: string; // 顧客名
  ckana: string; // 顧客名カナ
  ctel: string; // 電話番号
  cpost: string; // 郵便番号
  caddr: string; // 住所
  cmemo: string; // 備考
  cpwc: string; // 電力会社
  cpwn: string; // 電力需給No
  chsag: string; // 築年数
  cdnkd: string; // 電気代
  cgusd: string; // ガス代
  ctoud: string; // 灯油代
  cfami: string; // 家族構成
  cpno: string; //	顧客商品No（契約No）
  condd: string; //	契約日
  insdd: string; //	設置日
  conval: string; //	契約金額
  conarari: string; //	粗利
  ap_uid: OptionDTO; //	アポ担当
  con_uid: OptionDTO; //	契約担当
  payment_method: OptionDTO; //	決済方法
  construction_shop: string; //	施工店
  payment_day: string; //	入金日
  credit_syo: string; //	クレジット契約書
  keiyaku_syo: string; //	請負契約書
  jyuyo_syo: string; //	重要事項説明書
  other01: string; //	委任状
  other02: string; //	印鑑証明
  other03: string; //	謄本
  other04: string; //	電力申請
  other05: string; //	設備認定
  other06: string; //	保証書①
  other07: string; //	保証書②
  other08: string; //	現地調査依頼書
  other09: string; //	電気明細書
  apdt: string; //	アポ取得日
  syodt: string; //	商談日
  gendt: string; //	現調日
  kujdt1: string; //	工事日①
  kujdt2: string; //	工事日②
  kujdt3: string; //	工事日③
  kandt: string; //	完工日
  rendt: string; //	連系日
  hosdt: string; //	保証申請完了日
}

interface StatusData {
  ststxt: string;
  stsval: number;
}

export function ClientInformationModal({
  modalIsOpen,
  setIsOpen,
  mpid,
  csid,
}: Props) {
  const { control, handleSubmit, setValue, getValues, resetField } = useForm();
  const [nowHeader, setNowHeader] = useState("clientDetail");
  const [loading, setLoading] = useState(true);

  const [clientDetail, setClientDetail] = useState<ClientDetailDTO>();

  const [staff, setStaff] = useState<StaffDTO[]>();
  const [statusData, setStatusData] = useState<StatusData[]>();

  const paymentMethod = useMemo(() => {
    return [
      { label: "現金", value: 0 },
      { label: "クレジット（オリコ）", value: 1 },
      { label: "クレジット（アプラス）", value: 2 },
    ];
  }, []);

  const setValueInInput = useCallback(
    (data: ClientDetailDTO) => {
      // 顧客情報
      const findSts = statusData?.find(
        (s) => s.stsval === Number(data.csilst.sts)
      );
      const sts = {
        value: findSts?.stsval,
        label: findSts?.ststxt,
      };

      setValue("sts", sts);
      setValue("cnm", data.csilst.cnm);
      setValue("ckana", data.csilst.ckana);
      setValue("ctel", data.csilst.ctel);
      setValue("cpost", data.csilst.cpost);
      setValue("caddr", data.csilst.caddr);
      setValue("cmemo", data.csilst.cmemo);

      // 家庭情報
      setValue("cpwc", data.famlst.cpwc);
      setValue("cpwn", data.famlst.cpwn);
      setValue("chsag", data.famlst.chsag);
      setValue("cdnkd", data.famlst.cdnkd);
      setValue("cgusd", data.famlst.cgusd);
      setValue("ctoud", data.famlst.ctoud);
      setValue("cfami", data.famlst.cfami);

      // 契約情報
      data.conlst.forEach((d) => {
        const index = data.conlst.findIndex((item) => item === d) + 1;

        // アポ社員
        const findAp = staff?.find((s) => s.uid === Number(d.base.ap_uid));
        const ap_uid = {
          value: findAp?.uid,
          label: findAp?.unm,
        };

        // 契約社員
        const findCon = staff?.find((s) => s.uid === Number(d.base.con_uid));

        const con_uid = {
          value: findCon?.uid,
          label: findCon?.unm,
        };

        // 支払い方法
        const payment_method = paymentMethod?.find(
          (s) => s.value === Number(d.base.payment_method)
        );

        const condd = parseStringToDate(d.base.condd);
        const insdd = parseStringToDate(d.base.insdd);
        const payment_day = parseStringToDate(d.base.payment_day);

        // 基本情報
        setValue(`${index}cpno`, String(d.base.cpno));
        setValue(`${index}condd`, condd);
        setValue(`${index}insdd`, insdd);
        setValue(`${index}conval`, String(d.base.conval));
        setValue(`${index}conarari`, String(d.base.conarari));
        setValue(`${index}ap_uid`, ap_uid);
        setValue(`${index}con_uid`, con_uid);
        setValue(`${index}payment_method`, payment_method);
        setValue(`${index}construction_shop`, d.base.construction_shop);
        setValue(`${index}payment_day`, payment_day);

        // 書類情報
        setValue(`${index}credit_syo`, d.doc.credit_syo);
        setValue(`${index}keiyaku_syo`, d.doc.keiyaku_syo);
        setValue(`${index}jyuyo_syo`, d.doc.jyuyo_syo);
        setValue(`${index}other01`, d.doc.other01);
        setValue(`${index}other02`, d.doc.other02);
        setValue(`${index}other03`, d.doc.other03);
        setValue(`${index}other04`, d.doc.other04);
        setValue(`${index}other05`, d.doc.other05);
        setValue(`${index}other06`, d.doc.other06);
        setValue(`${index}other07`, d.doc.other07);
        setValue(`${index}other08`, d.doc.other08);
        setValue(`${index}other09`, d.doc.other09);

        // 進捗
        const apdt = parseStringToDate(d.progress.apdt);
        const syodt = parseStringToDate(d.progress.syodt);
        const gendt = parseStringToDate(d.progress.gendt);
        const kujdt1 = parseStringToDate(d.progress.kujdt1);
        const kujdt2 = parseStringToDate(d.progress.kujdt2);
        const kujdt3 = parseStringToDate(d.progress.kujdt3);
        const kandt = parseStringToDate(d.progress.kandt);
        const rendt = parseStringToDate(d.progress.rendt);
        const hosdt = parseStringToDate(d.progress.hosdt);

        setValue(`${index}apdt`, apdt);
        setValue(`${index}syodt`, syodt);
        setValue(`${index}gendt`, gendt);
        setValue(`${index}kujdt1`, kujdt1);
        setValue(`${index}kujdt2`, kujdt2);
        setValue(`${index}kujdt3`, kujdt3);
        setValue(`${index}kandt`, kandt);
        setValue(`${index}rendt`, rendt);
        setValue(`${index}hosdt`, hosdt);

        // taiyoukou tiku ecocute IH

        // 商品カテゴリ
        setValue(`${index}ecoMaker`, d.prdlst[2].company);
        setValue(`${index}ecoCapacity`, d.prdlst[2].capacity);
        setValue(`${index}ecoWarranty`, d.prdlst[2].hosyo);

        setValue(`${index}batteryMaker`, d.prdlst[1].company);
        setValue(`${index}batteryCapacity`, d.prdlst[1].capacity);
        setValue(`${index}batteryWarranty`, d.prdlst[1].hosyo);

        setValue(`${index}taiyokoMaker`, d.prdlst[0].company);
        setValue(`${index}taiyokoCapacity`, d.prdlst[0].capacity);
        setValue(`${index}taiyokoModule`, d.prdlst[0].module);
        setValue(`${index}powercon`, d.prdlst[0].powercon);
        setValue(`${index}taiyokoId`, d.prdlst[0].hinban);
        setValue(`${index}taiyokoWarranty`, d.prdlst[0].hosyo);

        setValue(`${index}ihMaker`, d.prdlst[3].company);
        setValue(`${index}ihCapacity`, d.prdlst[3].capacity);
        setValue(`${index}ihWarranty`, d.prdlst[3].hosyo);
      });
    },
    [paymentMethod, setValue, staff, statusData]
  );

  const loadList = useCallback(async () => {
    // params 内容
    const data = {
      ukey: "trial",
      fid: 17,
      mpid,
      csid,
    };
    const params = encodedParams(data);
    const response = await api.post("/", params);

    setClientDetail(response.data.data); // 顧客情報の保管
    setValueInInput(response.data.data); // 現在入力されている分をセット
  }, [csid, mpid, setValueInInput]);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      setLoading(true);

      // 社員一覧
      const staffData = {
        ukey: "trial",
        fid: 16,
      };
      const staffParams = encodedParams(staffData);
      const staffResponse = await api.post("/", staffParams);

      // ステータス読み込み
      const statusData = {
        ukey: "trial",
        fid: 15,
      };
      const statusParams = encodedParams(statusData);
      const statusReponse = await api.post("/", statusParams);

      setStaff(staffResponse.data.data.usrlst); // スタッフリスト情報
      setStatusData(statusReponse.data.data.csilst); // ステータスリスト情報
      loadList();

      setLoading(false);
    })();
  }, [csid, mpid]);

  async function handleSave(form: FormData) {
    try {
      // 既存登録の場合
      let conlst: ContractListDTO[] = [];
      if (clientDetail) {
        conlst = clientDetail.conlst.map((d) => {
          const index =
            clientDetail?.conlst.findIndex((item) => item === d) + 1;
          // 基本情報
          const cpno = getValues(`${index}cpno`);
          const condd = getValues(`${index}condd`);
          const insdd = getValues(`${index}insdd`);
          const conval = getValues(`${index}conval`);
          const conarari = getValues(`${index}conarari`);
          const apo = getValues(`${index}ap_uid`);
          const con = getValues(`${index}con_uid`);
          const pay = getValues(`${index}payment_method`);
          const construction_shop = getValues(`${index}construction_shop`);
          const payment_day = getValues(`${index}payment_day`);
          const base = {
            cpno,
            condd,
            insdd,
            conval,
            conarari,
            ap_uid: apo?.value,
            con_uid: con?.value,
            payment_method: pay?.value,
            construction_shop,
            payment_day,
          };
          // 書類情報
          const credit_syo = getValues(`${index}credit_syo`);
          const keiyaku_syo = getValues(`${index}keiyaku_syo`);
          const jyuyo_syo = getValues(`${index}jyuyo_syo`);
          const other01 = getValues(`${index}other01`);
          const other02 = getValues(`${index}other02`);
          const other03 = getValues(`${index}other03`);
          const other04 = getValues(`${index}other04`);
          const other05 = getValues(`${index}other05`);
          const other06 = getValues(`${index}other06`);
          const other07 = getValues(`${index}other07`);
          const other08 = getValues(`${index}other08`);
          const other09 = getValues(`${index}other09`);
          const doc = {
            credit_syo,
            keiyaku_syo,
            jyuyo_syo,
            other01,
            other02,
            other03,
            other04,
            other05,
            other06,
            other07,
            other08,
            other09,
          };
          // 進捗
          const apdt = getValues(`${index}apdt`);
          const syodt = getValues(`${index}syodt`);
          const gendt = getValues(`${index}gendt`);
          const kujdt1 = getValues(`${index}kujdt1`);
          const kujdt2 = getValues(`${index}kujdt2`);
          const kujdt3 = getValues(`${index}kujdt3`);
          const kandt = getValues(`${index}kandt`);
          const rendt = getValues(`${index}rendt`);
          const hosdt = getValues(`${index}hosdt`);
          const progress = {
            apdt,
            syodt,
            gendt,
            kujdt1,
            kujdt2,
            kujdt3,
            kandt,
            rendt,
            hosdt,
          };
          // 太陽光・蓄電池・エコキュート・IH
          const prdlst = [];
          prdlst.push(
            {
              // 太陽光
              idx: 1,
              company: getValues(`${index}taiyokoMaker`),
              capacity: getValues(`${index}taiyokoCapacity`),
              module: getValues(`${index}taiyokoModule`),
              powercon: getValues(`${index}powercon`),
              hinban: getValues(`${index}taiyokoId`),
              hosyo: getValues(`${index}taiyokoWarranty`),
            },
            {
              // 蓄電池
              idx: 2,
              company: getValues(`${index}batteryMaker`),
              capacity: getValues(`${index}batteryCapacity`),
              hosyo: getValues(`${index}batteryWarranty`),
            },
            {
              // EQ
              idx: 3,
              company: getValues(`${index}ecoMaker`),
              capacity: getValues(`${index}ecoCapacity`),
              hosyo: getValues(`${index}ecoWarranty`),
            },
            {
              // IH
              idx: 4,
              company: getValues(`${index}ihMaker`),
              capacity: getValues(`${index}ihCapacity`),
              hosyo: getValues(`${index}ihWarranty`),
            }
          );

          return {
            base,
            doc,
            progress,
            prdlst,
          };
        });
      }

      const cpno = getValues("newcpno");
      const condd = getValues("newcondd");
      const insdd = getValues("newinsdd");
      const conval = getValues("newconval");
      const conarari = getValues("newconarari");
      const apo = getValues("newap_uid");
      const con = getValues("newcon_uid");
      const pay = getValues("newpayment_method");
      const construction_shop = getValues("newconstruction_shop");
      const payment_day = getValues("newpayment_day");
      const base = {
        cpno,
        condd,
        insdd,
        conval,
        conarari,
        ap_uid: apo?.value,
        con_uid: con?.value,
        payment_method: pay?.value,
        construction_shop,
        payment_day,
      };
      const credit_syo = getValues("newcredit_syo");
      const keiyaku_syo = getValues("newkeiyaku_syo");
      const jyuyo_syo = getValues("newjyuyo_syo");
      const other01 = getValues("newother01");
      const other02 = getValues("newother02");
      const other03 = getValues("newother03");
      const other04 = getValues("newother04");
      const other05 = getValues("newother05");
      const other06 = getValues("newother06");
      const other07 = getValues("newother07");
      const other08 = getValues("newother08");
      const other09 = getValues("newother09");
      const doc = {
        credit_syo,
        keiyaku_syo,
        jyuyo_syo,
        other01,
        other02,
        other03,
        other04,
        other05,
        other06,
        other07,
        other08,
        other09,
      };
      const apdt = getValues("newapdt");
      const syodt = getValues("newsyodt");
      const gendt = getValues("newgendt");
      const kujdt1 = getValues("newkujdt1");
      const kujdt2 = getValues("newkujdt2");
      const kujdt3 = getValues("newkujdt3");
      const kandt = getValues("newkandt");
      const rendt = getValues("newrendt");
      const hosdt = getValues("newhosdt");
      const progress = {
        apdt,
        syodt,
        gendt,
        kujdt1,
        kujdt2,
        kujdt3,
        kandt,
        rendt,
        hosdt,
      };
      //　太陽光
      // 蓄電池
      // エコキュート
      // IH
      const prdlst = [];
      prdlst.push(
        {
          // 太陽光
          idx: 1,
          company: getValues("newtaiyokoMaker"),
          capacity: getValues("newtaiyokoCapacity"),
          module: getValues("newtaiyokoModule"),
          powercon: getValues("newpowercon"),
          hinban: getValues("newtaiyokoId"),
          hosyo: getValues("newtaiyokoWarranty"),
        },
        {
          // 蓄電池
          idx: 2,
          company: getValues("newbatteryMaker"),
          capacity: getValues("newbatteryCapacity"),
          hosyo: getValues("newbatteryWarranty"),
        },
        {
          // EQ
          idx: 3,
          company: getValues("newecoMaker"),
          capacity: getValues("newecoCapacity"),
          hosyo: getValues("newecoWarranty"),
        },
        {
          // IH
          idx: 4,
          company: getValues("newihMaker"),
          capacity: getValues("newihCapacity"),
          hosyo: getValues("newihWarranty"),
        }
      );

      conlst.push({
        base,
        doc,
        progress,
        prdlst,
      });

      const data = {
        ukey: "trial",
        fid: 18,
        mpid: mpid,
        csid: csid,
      };

      const csilst = {
        sts: form.sts.value,
        cnm: form.cnm,
        ckana: form.ckana,
        ctel: form.ctel,
        cpost: form.cpost,
        caddr: form.caddr,
        cmemo: form.cmemo,
      };
      const famlst = {
        cpwc: form.cpwc,
        cpwn: form.cpwn,
        chsag: form.chsag,
        cdnkd: form.cdnkd,
        cgusd: form.cgusd,
        ctoud: form.ctoud,
        cfami: form.cfami,
      };

      const params = encodedParams(data);
      params.append("csilst", JSON.stringify(csilst));
      params.append("famlst", JSON.stringify(famlst));
      params.append("conlst", JSON.stringify(conlst));

      await api.post("/", params);
      return loadList();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ReactModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <h2>【顧客情報】</h2>
        </Header>

        <Content>
          <ClientDetailHeader
            nowHeader={nowHeader}
            setNowHeader={setNowHeader}
            currentContract={clientDetail?.conlst || []}
          />

          {loading ? (
            <p>...Load </p>
          ) : (
            <>
              {nowHeader === "clientDetail" && (
                <ClientDetail control={control} />
              )}
              {nowHeader === "clientFamily" && (
                <ClientFamily control={control} />
              )}

              {clientDetail &&
                clientDetail.conlst.length > 0 &&
                clientDetail.conlst.map((c) => {
                  const index =
                    clientDetail.conlst.findIndex((item) => item === c) + 1;

                  if (nowHeader === `${index}contract`) {
                    return (
                      <AddContract
                        resetField={resetField}
                        key={String(index)}
                        control={control}
                        id={String(index)}
                        csid={csid}
                        mpid={mpid}
                        cpno={c.base.cpno}
                        setValue={setValue}
                      />
                    );
                  }
                  return;
                })}

              {nowHeader === "addContract" && (
                <AddContract
                  resetField={resetField}
                  control={control}
                  csid={csid}
                  mpid={mpid}
                />
              )}
            </>
          )}

          <SaveButton onClick={handleSubmit(handleSave)} />
        </Content>
      </Container>
    </ReactModal>
  );
}
