import { SortDTO } from "../dtos/SortDTO";

interface Props {
  sortNumber: number;
  sort: SortDTO;
  setSort: (sort: SortDTO) => void;
}

export function handleSetSort({ sort, setSort, sortNumber }: Props) {
  if (sort.sort === sortNumber && sort.desc === 0) {
    return setSort({
      desc: 1,
      sort: sortNumber,
    });
  }

  if (sort.sort === sortNumber && sort.desc === 1) {
    return setSort({
      desc: 0,
      sort: sortNumber,
    });
  }

  return setSort({
    desc: 1,
    sort: sortNumber,
  });
}
