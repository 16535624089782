import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${colors.background_secondary};
`;

export const StyledInput = styled.input`
  width: 100%;
  flex: 1;
  border: 1px solid ${colors.border_200};

  color: ${colors.text_detail};

  font-size: 0.9375rem;
  font-family: ${fonts.primary};

  font-weight: 500;

  background-color: ${colors.background_primary};
  padding: 0.7rem 1.85rem;
`;
