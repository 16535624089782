import Select, {
  components,
  DropdownIndicatorProps,
  PlaceholderProps,
} from "react-select";

import { RiAccountPinBoxFill } from "react-icons/ri";

import { Container } from "./styles";

const Placeholder = ({ ...rest }: PlaceholderProps) => {
  return <components.Placeholder {...rest} />;
};

const DropdownIndicator = ({ ...rest }: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...rest}>
      <RiAccountPinBoxFill size={20} color="#8B99A7" />
    </components.DropdownIndicator>
  );
};

interface Props {
  options: {
    label: string;
    value: string;
  }[];
  setData: (data: any) => void;
}

export function StyledSelect({ options, setData }: Props) {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      minWidth: "160px",
      maxWidth: "270px",
    }),
    control: () => ({
      padding: "6px 20px",
      border: "0",
      outline: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "0.9375rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      fontWeight: 500,
      color: "#A2A6B1",
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: "0.9375rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      fontWeight: 500,
      color: "#A2A6B1",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "0.9375rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      fontWeight: 500,
      color: "#A2A6B1",
    }),
  };

  function handleChangeData(newValue: any) {
    setData(newValue.value);
  }
  return (
    <Container>
      <Select
        closeMenuOnSelect={false}
        components={{
          Placeholder,
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        placeholder={""}
        options={options}
        styles={customStyles}
        onChange={(newValue) => handleChangeData(newValue)}
      />
    </Container>
  );
}
