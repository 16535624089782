import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const GraphContent = styled.div`
  width: 49%;
  max-height: 560px;

  background: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  padding: 30px;

  display: flex;
  justify-content: space-between;
`;

export const GraphDetail = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
`;

export const GraphTitle = styled.h3`
  font-size: 0.8125rem;
  font-family: ${fonts.secondary};
  font-weight: 500;
  color: ${colors.text_detail};
`;

export const GraphStaffName = styled.strong`
  font-size: 1rem;
  font-family: ${fonts.primary};
  font-weight: 600;
  color: ${colors.text};

  margin: 7px 0 13px;
`;

export const GraphDetailDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 5px;
`;

export const GraphDetailData = styled.div`
  width: 20%;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 10px;
    font-family: ${fonts.primary};
    font-weight: 600;
    color: ${colors.text_detail};
  }

  svg {
    margin-right: 12px;
  }

  margin-top: 5px;
`;

export const Graphs = styled.div`
  width: 80%;
  display: flex;
  flex: 1;
  position: relative;
`;
