import styled, { keyframes } from "styled-components";
import { colors, fonts } from "../../styles/theme";

export const Container = styled.header`
  width: 100%;
  height: 100px;

  background: ${colors.background_primary};

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media print {
    display: none;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 15.6vw;
  min-width: 260px;
  max-width: 300px;

  h1 {
    font-weight: 600;
    font-family: ${fonts.secondary};
    color: ${colors.main};
    font-size: 1.375rem;
    padding: 0 12px;

    margin: 0 auto;
  }
`;

export const Border = styled.div`
  width: 1px;
  height: 55px;

  background: ${colors.border_100};
  margin-left: auto;
`;

export const RankingContainer = styled.div`
  width: 100%;
  /* max-width: 1460px; */

  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;

  margin: 0 auto;

  overflow: hidden;
  white-space: nowrap;

  background: ${colors.background_primary};
`;

const loop = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const Ranking = styled.div`
  display: flex;
  flex-wrap: nowrap;

  -webkit-animation: ${loop} 36s infinite linear 1s both;
  animation: ${loop} 36s infinite linear 1s both;

  p {
    display: flex;
    margin-right: 30px;
    font-size: 1.125rem;
    font-weight: 600;
    color: ${colors.text};
  }

  div {
    display: flex;
    margin-right: 42px;

    p {
      color: ${colors.text};

      font-weight: 400;
      font-size: 1rem;
    }
  }

  &:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
`;
