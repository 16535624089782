import styled from "styled-components";
import { colors, fonts } from "../../../../styles/theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 6px 10px 20px;

  background: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;
`;

export const StyledInput = styled.input`
  width: 100%;

  border: none;
  outline: none;

  color: ${colors.text_detail};

  font-size: 0.9375rem;
  font-family: ${fonts.secondary};

  font-weight: 600;
`;
