import { ApexOptions } from "apexcharts";
import { colors, fonts } from "../../../styles/theme";

export function option(): ApexOptions {
  return {
    chart: {
      type: "line",
    },
    stroke: {
      width: [0, 6],
      colors: ["#FED67D"],
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      colors: ["#66DDDD"],
      opacity: 1,
    },
    labels: [
      "2021/12/1",
      "2021/12/2",
      "2021/12/3",
      "2021/12/4",
      "2021/12/5",
      "2021/12/6",
      "2021/12/7",
      "2021/12/8",
      "2021/12/9",
      "2021/12/10",
      "2021/12/11",
      "2021/12/12",
      "2021/12/13",
      "2021/12/14",
      "2021/12/15",
      "2021/12/16",
      "2021/12/17",
      "2021/12/18",
      "2021/12/19",
      "2021/12/20",
      "2021/12/21",
      "2021/12/22",
      "2021/12/23",
      "2021/12/24",
      "2021/12/25",
      "2021/12/26",
      "2021/12/27",
      "2021/12/28",
      "2021/12/29",
      "2021/12/30",
      "2021/12/31",
    ],
    grid: {
      show: false,
    },
    xaxis: {
      type: "datetime",
      axisTicks: {
        show: false,
      },
      labels: {
        format: "MM/dd",
        datetimeUTC: false,
        style: {
          colors: colors.title,
          fontSize: "9px",
          fontWeight: 600,
          fontFamily: fonts.secondary,
        },
      },
    },
    legend: {
      show: false,
    },
    yaxis: [
      {
        labels: {
          show: false,
        },
        opposite: true,
        min: 0,
        max: 10000,
      },
      {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
          style: {
            colors: colors.title,
            fontSize: "9px",
            fontWeight: 600,
            fontFamily: fonts.secondary,
          },
        },
      },
    ],
  };
}
