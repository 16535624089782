import { HTMLAttributes } from "react";
import { Control, Controller } from "react-hook-form";

import { Section, Label, Image, InputContainer, Input } from "./styles";

interface Props extends HTMLAttributes<HTMLInputElement> {
  title: string;
  name: string;
  control: Control;
  unit?: string;
  sectionStyle?: object;
}
export function StaffImageSection({
  name,
  control,
  title,
  unit,
  sectionStyle = {},
  ...rest
}: Props) {
  return (
    <Section style={sectionStyle}>
      <Label>{title}</Label>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputContainer>
            {value && <Image src={value} alt={title} />}
            <label htmlFor={name}>
              <Input type="file" id={name} onChange={onChange} {...rest} />
            </label>
          </InputContainer>
        )}
        name={name}
      />
    </Section>
  );
}
