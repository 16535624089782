import { useCallback, useEffect, useState } from "react";
import { ClientInformationModal } from "../../components/Modals/ClientInformationModal";

import { StyledSelect } from "../../components/StyledSelect";

import api from "../../services/api";
import { encodedParams } from "../../utils/encodedParams";

import { StaffDTO } from "../../dtos/StaffDTO";
import { AlartCountListDTO, AlartDTO } from "../../dtos/AlartDTO";
import { OpenModalDTO } from "../../dtos/OpenModalDTO";

import {
  Container,
  Header,
  StyledLink,
  Title,
  FilterContainer,
  FilterBox,
  TableFilter,
  FilterDetail,
  Table,
  TableHead,
  TableBody,
  TableRow,
  BodyRow,
  TableHeadColumn,
  TableBodyColumn,
} from "./styles";

interface OptionProps {
  label: string;
  value: string;
}

interface FormData {
  fid: number; // 処理ID
  ukey: string; // ユーザーKEY
  uid?: number; // 社員ID
  alert_type?: number[]; // アラートタイプ
}

export function Event() {
  // const [modalIsOpen, setIsOpen] = useState(false);
  // const [modalMpid, setModalMpid] = useState("");
  // const [modalCsid, setModalCsid] = useState("");

  const [filterStaff, setFilterStaff] = useState<string | undefined>();
  const [staffOptions, setStaffOptions] = useState<OptionProps[]>(
    [] as OptionProps[]
  );
  const [alartList, setAlartList] = useState<AlartDTO[]>();
  const [alartCountList, setAlartCountList] = useState<AlartCountListDTO>();

  const load = useCallback(async () => {
    try {
      let data: FormData = {
        ukey: "trial",
        fid: 23,
      };

      if (filterStaff) {
        data = {
          ...data,
          uid: Number(filterStaff),
        };
      }

      console.log(data);

      const params = encodedParams(data);
      const response = await api.post("/", params);

      setAlartCountList(response.data.data.cntlst);
      setAlartList(response.data.data.altlst);
    } catch (error) {
      console.log(error);
    }
  }, [filterStaff]);

  // api初期読み込み
  useEffect(() => {
    (async () => {
      // params 内容
      const data = {
        ukey: "trial",
        fid: 16,
      };
      const params = encodedParams(data);
      const response = await api.post("/", params);

      const parseData = response.data.data.usrlst.map((d: StaffDTO) => {
        return {
          label: d.unm,
          value: d.uid,
        };
      });
      load();
      setStaffOptions(parseData);
    })();
  }, [load]);

  // // モーダルオープン
  // function handleOpenModal({ mpid, csid }: OpenModalDTO) {
  //   setModalMpid(String(mpid));
  //   setModalCsid(String(csid));
  //   setIsOpen(true);
  // }

  return (
    <Container>
      <Header>
        <Title>EVENT</Title>

        <FilterContainer>
          <StyledLink href="#" isSelected>
            リスト
          </StyledLink>
          <StyledLink href="#">カレンダー</StyledLink>
        </FilterContainer>
      </Header>

      <TableFilter>
        <FilterDetail>
          全{alartCountList?.total}件（期限超過:{alartCountList?.over}件／予定:
          {alartCountList?.plan}件）
        </FilterDetail>

        <FilterBox>
          <StyledSelect setData={setFilterStaff} options={staffOptions} />
        </FilterBox>
      </TableFilter>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadColumn className="medium">
              <p>日時</p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>タイプ</p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>社員名</p>
            </TableHeadColumn>
            <TableHeadColumn className="short">
              <p>顧客名</p>
            </TableHeadColumn>
            <TableHeadColumn className="long">
              <p>住所</p>
            </TableHeadColumn>
            <TableHeadColumn className="extra-large">
              <p>備考</p>
            </TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {alartList &&
            alartList.map((item) => (
              <BodyRow
                key={item.dt + item.alert_type + item.unm}
                // onClick={() => handleOpenModal({mdid: item.})}
              >
                <TableBodyColumn className="medium">
                  <strong>{item.dt}</strong>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.alert_type}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.unm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="short">
                  <p>{item.csnm}</p>
                </TableBodyColumn>
                <TableBodyColumn className="long">
                  <p>{item.addr}</p>
                </TableBodyColumn>
                <TableBodyColumn className="extra-large">
                  <p>{item.memo}</p>
                </TableBodyColumn>
              </BodyRow>
            ))}
        </TableBody>
      </Table>

      {/* <ClientInformationModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        mpid={modalMpid}
        csid={modalCsid}
      /> */}
    </Container>
  );
}
