import { Routes, Route } from "react-router-dom";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar";

import { Timeline } from "../pages/Timeline";
import { Event } from "../pages/Event";
import { DailyReport } from "../pages/DailyReport";

import { NewCustomer } from "../pages/NewCustomer";
import { Contract } from "../pages/Contract";
import { Staff } from "../pages/Staff";
import { Setting } from "../pages/Setting";
import { Record } from "../pages/Setting/Record";
import { About } from "../pages/Setting/About";
import { Dashboard } from "../pages/Dashboard";

import {
  Container,
  SettingContainer,
  SettingHeader,
  StyledLink,
  Title,
  FilterContainer,
  Content,
} from "./styles";

export default function Router() {
  return (
    <Container>
      <Header />

      <main>
        <Sidebar />
        <Routes>
          <Route path="/" element={<Timeline />} />
          <Route path="/event" element={<Event />} />
          <Route path="/daily-report" element={<DailyReport />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/new-customer" element={<NewCustomer />} />
          <Route path="/contract" element={<Contract />} />
          <Route path="/staff" element={<Staff />} />

          <Route path="/setting" element={<Setting />} />
          <Route path="/setting/record" element={<Record />} />
          <Route path="/setting/about" element={<About />} />

          {/* <Route path="/gps" element={} /> */}
          {/* <Route path="/visit" element={} /> */}
          {/* <Route path="/message" element={} /> */}
        </Routes>
      </main>
    </Container>
  );
}
