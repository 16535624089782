import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

interface SortProps {
  desc: number; // 0:昇順／1･未設定:降順
  sort: number; //0･未設定:日時／1:社員／2:顧客名／3:住所／4:追客状況／5:アポ／6:商談／7:契約／8～N:現況(xxxx)
}

interface Props {
  sort: SortProps;
  sortNumber: number;
}

export function SortIcon({ sort, sortNumber }: Props) {
  if (sort.sort === sortNumber && sort.desc === 1) {
    return <FaSortDown size={14} />;
  }

  if (sort.sort === sortNumber && sort.desc !== 1) {
    return <FaSortUp size={14} />;
  }

  return sort.sort !== sortNumber && <FaSort size={14} />;
}
