export const colors = {
  background_primary: "#FFFFFF",
  background_secondary: "#F9F9FC",
  background_third: "#F7F8F8",

  text: "#8B99A7",
  text_detail: "#A2A6B1",
  title: "#77838E",

  border_0: "#FFFFFF",
  border_100: "#E5E6EC",
  border_200: "#E5E9F2",
  border_300: "#A2A6B1",
  border_400: "#707070",

  main: "#3BB0BA",
  main_100: "#369FA8",
  main_200: "#4BD6D8",
  main_300: "#40BFC1",

  chart_1: "#66DDDD",
  chart_2: "#FED67D",
  chart_3: "#AADE87",
  chart_4: "#F38593",
  chart_5: "#CAA6DB",
  chart_6: "#E75A5F",
  chart_7: "#3BB0BA",
};

export const fonts = {
  primary: "Hiragino Kaku Gothic ProN",
  secondary: "Montserrat",
};
