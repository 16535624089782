import React, { HTMLAttributes } from "react";
import { Input } from "../../Contract/Input";

import { Container } from "./styles";

interface Props extends HTMLAttributes<HTMLInputElement> {
  title?: string;
  data: string;
  setData: (data: string) => void;
  containerStyle?: object;
  unit?: string;
}
export function TextInput({
  title,
  containerStyle,
  data,
  unit,
  setData,
  ...rest
}: Props) {
  return (
    <Container style={containerStyle}>
      {title && title}
      <Input
        value={data}
        setData={setData}
        style={{ width: "60px", margin: "0 15px", padding: "0.45rem 1.85rem" }}
        {...rest}
      />
      {unit && unit}
    </Container>
  );
}
