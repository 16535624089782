import { forwardRef, useRef } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import Input from "./Input";

registerLocale("ja", ja);

interface Props {
  date: Date | undefined | null;
  setDate: (date: Date | null) => void;
  timeOnly?: boolean;
  icon?: "time" | "calendar";
}

const CustomInput = forwardRef((props: any, ref) => {
  return <Input {...props} ref={ref} />;
});

export function SelectDate({ setDate, date, icon, timeOnly = false }: Props) {
  const inputRef = useRef(null);

  return (
    <DatePicker
      locale="ja"
      dateFormat={!timeOnly ? "yyyy.MM.dd" : "HH:mm"}
      selected={date}
      customInput={<CustomInput icon={icon} inputRef={inputRef} />}
      onChange={(date: Date | null) => setDate(date)}
      showTimeSelect={timeOnly}
      showTimeSelectOnly={timeOnly}
    />
  );
}
