import styled from "styled-components";
import { colors, fonts } from "../../../../styles/theme";

export const Section = styled.div`
  width: 150px;

  margin-right: 30px;
`;

export const Label = styled.p`
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-family: ${fonts.primary};

  color: ${colors.title};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;

  background: ${colors.border_200};

  label {
    height: 100px;
    width: 150px;
    display: flex;
    flex: 1;

    cursor: pointer;
    background: transparent;

    position: relative;
  }
`;

export const Input = styled.input`
  display: none;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 150px;
  height: 100px;

  object-fit: contain;
`;
