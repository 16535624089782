import { MdCircle } from "react-icons/md";
import { colors } from "../../../styles/theme";

import Chart from "react-apexcharts";

import {
  GraphContent,
  GraphDetail,
  GraphTitle,
  GraphStaffName,
  GraphDetailDataContainer,
  GraphDetailData,
  Graphs,
} from "./styles";
import { option } from "./option";

export function StaffChart() {
  const chart_1_options = option(colors.chart_1, "訪問数");
  const chart_2_options = option(colors.chart_2, "アポ");
  const chart_3_options = option(colors.chart_3, "再訪");
  const chart_4_options = option(colors.chart_4, "契約");
  const chart_5_options = option(colors.chart_5, "売上");
  const chart_6_options = option(colors.chart_6, "アポ率");
  const chart_7_options = option(colors.chart_7, "契約数");

  return (
    <GraphContent>
      <GraphDetail>
        <GraphTitle>Name</GraphTitle>
        <GraphStaffName>愛知　太郎</GraphStaffName>
        <GraphTitle>Item</GraphTitle>

        <GraphDetailDataContainer>
          <GraphDetailData>
            <p>
              <MdCircle size={8} color={colors.chart_1} />
              訪問数
            </p>
            <p>75/100件中</p>
          </GraphDetailData>
          <GraphDetailData>
            <p>
              <MdCircle size={8} color={colors.chart_2} />
              アポ
            </p>
            <p>75/100件中</p>
          </GraphDetailData>
          <GraphDetailData>
            <p>
              <MdCircle size={8} color={colors.chart_3} />
              再訪
            </p>
            <p>75/100件中</p>
          </GraphDetailData>
          <GraphDetailData>
            <p>
              <MdCircle size={8} color={colors.chart_4} />
              契約
            </p>
            <p>75/100件中</p>
          </GraphDetailData>
          <GraphDetailData>
            <p>
              <MdCircle size={8} color={colors.chart_5} />
              売上
            </p>
            <p>75/100件中</p>
          </GraphDetailData>
          <GraphDetailData>
            <p>
              <MdCircle size={8} color={colors.chart_6} />
              アポ率
            </p>
            <p>75/100件中</p>
          </GraphDetailData>
          <GraphDetailData>
            <p>
              <MdCircle size={8} color={colors.chart_7} />
              契約率
            </p>
            <p>75/100件中</p>
          </GraphDetailData>
        </GraphDetailDataContainer>
      </GraphDetail>
      <Graphs>
        <Chart
          options={chart_1_options}
          series={[67]}
          type="radialBar"
          height={100}
          width={100}
        />
        <Chart
          options={chart_2_options}
          series={[67]}
          type="radialBar"
          height={100}
          width={100}
        />
        <Chart
          options={chart_3_options}
          series={[67]}
          type="radialBar"
          height={100}
          width={100}
        />
        <Chart
          options={chart_4_options}
          series={[67]}
          type="radialBar"
          height={100}
          width={100}
        />
        <Chart
          options={chart_5_options}
          series={[67]}
          type="radialBar"
          height={100}
          width={100}
        />
        <Chart
          options={chart_6_options}
          series={[67]}
          type="radialBar"
          height={100}
          width={100}
        />
        <Chart
          options={chart_7_options}
          series={[67]}
          type="radialBar"
          height={100}
          width={100}
        />
      </Graphs>
    </GraphContent>
  );
}
