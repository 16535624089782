import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 40px;
  margin-bottom: auto;
`;

export const Section = styled.section`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-bottom: 37px;
`;
