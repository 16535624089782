import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  display: flex;
  background: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  color: ${colors.text_detail};

  font-size: 0.9375rem;
  font-family: ${fonts.secondary};

  font-weight: 500;
`;
