import styled from "styled-components";
import { colors, fonts } from "../../../../styles/theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: ${colors.background_secondary};
  svg {
    color: ${colors.text_detail};
  }
`;

export const StyledInput = styled.input`
  width: 90px;

  border: none;
  outline: none;

  color: ${colors.text_detail};

  font-size: 1.125rem;
  font-family: ${fonts.primary};

  font-weight: 500;

  background-color: ${colors.background_secondary};
`;
