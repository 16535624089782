import { ForwardedRef, forwardRef } from "react";

import { StyledInput } from "./styles";

interface InputProps {
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
}

function Input(
  { value, onClick, onChange }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <StyledInput
      type="text"
      value={value}
      ref={ref}
      onChange={(e) => onChange(e.target.value)}
      onClick={onClick}
    />
  );
}

export default forwardRef(Input);
