import { Control, FieldValues, UseFormResetField } from "react-hook-form";

import { SelectDateSection } from "../../../../Form/SelectDateSection";

import { Container, Section } from "./styles";

interface Props {
  control: Control;
  id: string;
  resetField: UseFormResetField<FieldValues>;
}

export function Progress({ control, id, resetField }: Props) {
  return (
    <Container>
      <Section>
        <SelectDateSection
          resetField={resetField}
          title="アポ取得日"
          name={`${id}apdt`}
          control={control}
        />
        <SelectDateSection
          resetField={resetField}
          title="商談日"
          name={`${id}syodt`}
          control={control}
        />
        <SelectDateSection
          resetField={resetField}
          title="現調日"
          name={`${id}gendt`}
          control={control}
        />
      </Section>

      <Section>
        <SelectDateSection
          resetField={resetField}
          title="工事日①"
          name={`${id}kujdt1`}
          control={control}
        />
        <SelectDateSection
          resetField={resetField}
          title="工事日②"
          name={`${id}kujdt2`}
          control={control}
        />
        <SelectDateSection
          resetField={resetField}
          title="工事日③"
          name={`${id}kujdt3`}
          control={control}
        />
      </Section>

      <Section>
        <SelectDateSection
          resetField={resetField}
          title="完工日"
          name={`${id}kandt`}
          control={control}
        />
        <SelectDateSection
          resetField={resetField}
          title="連携日"
          name={`${id}rendt`}
          control={control}
        />
        <SelectDateSection
          resetField={resetField}
          title="保証申請完了日"
          name={`${id}hosdt`}
          control={control}
        />
      </Section>
    </Container>
  );
}
