import styled from "styled-components";
import { colors, fonts } from "../styles/theme";

import { NavLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  min-height: 865px;

  main {
    height: calc(100% - 100px);
    display: flex;
  }

  @media print {
    width: 100%;
    height: 100%;
  }
`;

export const SettingContainer = styled.div`
  width: calc(100vw - 305px);
  display: flex;
  flex-direction: column;
`;

export const SettingHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  width: 76.24%;

  margin: 45px auto 20px;
  border-bottom: 1px solid ${colors.border_300};
`;

export const Title = styled.h2`
  font-size: 1.6875rem;
  font-family: ${fonts.secondary};

  font-weight: 600;
  color: ${colors.text_detail};

  padding-bottom: 15px;

  margin-right: 50px;
`;

export const StyledLink = styled(NavLink)`
  font-size: 1.125rem;
  font-family: ${fonts.primary};

  font-weight: 600;
  color: ${colors.text_detail};
  text-decoration: none;

  padding: 0 2px 15px;
  margin-right: 40px;

  padding-bottom: 18px;

  &[aria-current] {
    padding-bottom: none;
    color: ${colors.main};

    border-bottom: 3px solid ${colors.main};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: 16px;
`;

export const Content = styled.div`
  width: 76.24%;

  padding-bottom: 60px;
  margin: 0 auto;
`;
