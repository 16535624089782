import Select, {
  components,
  DropdownIndicatorProps,
  PlaceholderProps,
} from "react-select";

import { FaSort } from "react-icons/fa";

import { Section, SectionTitle } from "./styles";
import { Control, Controller } from "react-hook-form";

interface Props {
  title: string;
  options: {
    label: string;
    value: string;
  }[];
  name: string;
  control: Control;
  defaultValue?: any;
}

const Placeholder = ({ ...rest }: PlaceholderProps) => {
  return <components.Placeholder {...rest} />;
};

const DropdownIndicator = ({ ...rest }: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...rest}>
      <FaSort size={12} color="#8B99A7" />
    </components.DropdownIndicator>
  );
};

export function SelectSection({
  title,
  options,
  control,
  name,
  defaultValue,
}: Props) {
  const customStyles = {
    control: () => ({
      width: "100%",
      minWidth: "250px",
      maxWidth: "300px",
      padding: "4px 16px",
      outline: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "1rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      color: "#77838E",
      backgroundColor: "#Ffffff",
      border: "1px solid #E5E0F2",
      borderRadius: "3px",
    }),
    option: (provided: any) => ({
      ...provided,

      fontSize: "1rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      fontWeight: 500,
      color: "#77838E",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "1rem",
      fontFamily: "Montserrat, Hiragino Kaku Gothic ProN",
      fontWeight: 500,
      color: "#77838E",
    }),
  };

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              closeMenuOnSelect={false}
              components={{
                Placeholder,
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              placeholder=""
              options={options}
              styles={customStyles}
              onChange={onChange}
              value={value}
              defaultValue={defaultValue}
            />
          );
        }}
      />
    </Section>
  );
}
