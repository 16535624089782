import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  display: flex;

  color: ${colors.title};
  font-size: 0.9375rem;
  font-family: ${fonts.primary};
  font-weight: 500;

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;

    cursor: pointer;

    span {
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 3px;

      border: 1px solid ${colors.border_200};
      background-color: ${colors.background_primary};

      color: ${colors.background_primary};

      margin-right: 15px;
    }
  }

  input[type="checkbox"]:checked + label {
    span {
      background-color: ${colors.main};
    }
  }
`;
