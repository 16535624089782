import styled from "styled-components";
import { colors, fonts } from "../../../styles/theme";

export const Container = styled.div`
  display: flex;
  align-items: center;

  margin-left: 30px;

  color: ${colors.title};
  font-size: 0.9375rem;
  font-family: ${fonts.primary};
  font-weight: 500;
`;
