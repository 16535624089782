import { useState } from "react";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { addDays, subDays } from "date-fns";

import { SelectDate } from "../SelectDate";

import {
  Container,
  SelectDayButton,
  SelectDayContainer,
  SelectDateContent,
} from "./styles";
import { MonthSelect } from "../MonthSelect";

interface Options {
  label: string;
  value: number;
}

interface Props {
  date: Date;
  setDate: (date: Date) => void;
  month: number;
  setMonth: (month: number) => void;
  year: number;
  setYear: (year: number) => void;
}

export function SelectDateContainer({
  date,
  setDate,
  month,
  setMonth,
  year,
  setYear,
}: Props) {
  const defaultMonth = {
    label: `${month}月`,
    value: month,
  };
  const defaultYear = {
    label: `${year}年`,
    value: year,
  };

  function selectDate(day: Date) {
    if (day.getFullYear() !== year) {
      setYear(day.getFullYear());
    }

    if (day.getMonth() + 1 !== month) {
      setMonth(day.getMonth() + 1);
    }

    setDate(day);
  }

  function handleBackDay() {
    const day = subDays(date, 1);

    if (day.getFullYear() !== year) {
      setYear(day.getFullYear());
    }

    if (day.getMonth() + 1 !== month) {
      setMonth(day.getMonth() + 1);
    }

    setDate(day);
  }

  function handleNextDay() {
    const day = addDays(date, 1);

    if (day.getFullYear() !== year) {
      setYear(day.getFullYear());
    }

    if (day.getMonth() + 1 !== month) {
      setMonth(day.getMonth() + 1);
    }

    setDate(day);
  }

  return (
    <Container>
      <SelectDayButton onClick={handleBackDay}>
        <MdKeyboardArrowLeft size={32} />
      </SelectDayButton>

      <SelectDayContainer>
        <MonthSelect
          options={yearOptions}
          setData={setYear}
          data={defaultYear}
        />
        <MonthSelect
          options={monthOptions}
          setData={setMonth}
          data={defaultMonth}
        />
        <SelectDateContent>
          <SelectDate date={date} setDate={selectDate} />
        </SelectDateContent>
      </SelectDayContainer>

      <SelectDayButton onClick={handleNextDay}>
        <MdKeyboardArrowRight size={32} />
      </SelectDayButton>
    </Container>
  );
}

const monthOptions = [
  { value: 1, label: "1月" },
  { value: 2, label: "2月" },
  { value: 3, label: "3月" },
  { value: 4, label: "4月" },
  { value: 5, label: "5月" },
  { value: 6, label: "6月" },
  { value: 7, label: "7月" },
  { value: 8, label: "8月" },
  { value: 9, label: "9月" },
  { value: 10, label: "10月" },
  { value: 11, label: "11月" },
  { value: 12, label: "12月" },
];

const yearOptions: Options[] = [];
for (let i = new Date().getFullYear(); i >= 2000; i--) {
  yearOptions.push({
    value: i,
    label: `${i}年`,
  });
}
