// Interfaceを定義
interface Data {
  [key: string]: any;
}

export function encodedParams(data: Data) {
  const params = new URLSearchParams();

  Object.keys(data).forEach((key) => {
    params.append(key, data[key]);
  }, data);

  return params;
}
