import { ReactNode } from "react";

import { Container, Logo, Border, RankingContainer, Ranking } from "./styles";

interface HeaderProps {
  children?: ReactNode;
}

export function Header({ children }: HeaderProps) {
  return (
    <Container>
      <Logo>
        <h1>SUKESUKEKUN</h1>
        <Border />
      </Logo>

      <RankingContainer>
        <Ranking>
          <p>12月</p>

          <div>
            <div>
              <p>訪問数:1位 鈴木(7件) 2位 山田(5件) 3位 佐藤(3件)</p>
            </div>
            <div>
              <p>訪問数:1位 鈴木(7件) 2位 山田(5件) 3位 佐藤(3件)</p>
            </div>
            <div>
              <p>訪問数:1位 鈴木(7件) 2位 山田(5件) 3位 佐藤(3件)</p>
            </div>
            <div>
              <p>訪問数:1位 鈴木(7件) 2位 山田(5件) 3位 佐藤(3件)</p>
            </div>
          </div>

          <p>本日</p>
          <div>
            <div>
              <p>訪問数:1位 鈴木(7件) 2位 山田(5件) 3位 佐藤(3件)</p>
            </div>
            <div>
              <p>訪問数:1位 鈴木(7件) 2位 山田(5件) 3位 佐藤(3件)</p>
            </div>
            <div>
              <p>訪問数:1位 鈴木(7件) 2位 山田(5件) 3位 佐藤(3件)</p>
            </div>
            <div>
              <p>訪問数:1位 鈴木(7件) 2位 山田(5件) 3位 佐藤(3件)</p>
            </div>
          </div>
        </Ranking>
      </RankingContainer>
    </Container>
  );
}
