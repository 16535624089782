import styled from "styled-components";
import { colors, fonts } from "../../styles/theme";

export const Container = styled.div`
  width: calc(100vw - 305px);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 76.24%;
  min-width: 660px;

  margin: 45px auto 20px;
`;

export const Title = styled.h2`
  font-size: 1.6875rem;
  font-family: ${fonts.secondary};

  font-weight: 600;
  color: ${colors.text_detail};
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: 16px;
`;

export const FilterBox = styled.div`
  margin-left: 10px;
  width: 100%;
  min-width: 160px;
  max-width: 270px;

  svg {
    color: ${colors.title};
  }
`;

export const TodayProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;

  width: 76.24%;
  min-width: 660px;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};
  border-radius: 4px;

  h3 {
    font-size: 1.125rem;
    font-family: ${fonts.primary};

    font-weight: 400;
    color: ${colors.text};

    padding-top: 26px;
  }
`;

export const Border = styled.div`
  width: 90%;
  height: 1px;

  background-color: ${colors.border_200};
  margin-top: 12px;
`;

export const TodayProgressContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 26px auto 22px;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 40px;

  p {
    text-align: center;
    font-size: 1rem;
    font-family: ${fonts.primary};

    font-weight: 400;
    color: ${colors.text};
  }

  strong {
    margin-top: 8px;
    text-align: center;
    font-size: 1.6875rem;
    font-family: ${fonts.secondary};

    font-weight: 600;
    color: ${colors.text_detail};
  }
`;

export const TableFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 76.24%;
  min-width: 660px;

  margin: 40px auto 15px;
`;

export const SelectInput = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;

  cursor: pointer;

  label {
    cursor: pointer;

    min-width: 130px;
    padding: 9px 25px;

    border-radius: 45px;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);

    background-color: ${colors.background_primary};
    border: 1px solid ${colors.border_200};

    text-align: center;
    font-size: 0.6875rem;
    font-family: ${fonts.primary};

    font-weight: 500;
    color: ${colors.text};
  }

  input[type="checkbox"]:checked + label {
    background-color: ${colors.title};
    color: ${colors.background_primary};
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;

  width: 76.24%;
  min-width: 660px;

  height: 54.69%;

  margin: 0 auto;

  background-color: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  overflow: scroll;
  white-space: nowrap;

  position: relative;
`;

export const TableHead = styled.div`
  display: flex;

  position: sticky;
  top: 0;
`;

export const TableBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TableFooter = styled.div`
  color: ${colors.text};
  font-size: 0.875rem;
  font-family: ${fonts.primary};

  font-weight: 500;

  width: 76.24%;

  margin: 8px auto 4.5rem;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;

  height: 73px;
  background-color: ${colors.background_primary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

  border-bottom: 1px solid ${colors.border_200};

  button.short {
    width: 122px;
  }

  button.short-medium {
    width: 160px;
  }
  button.medium {
    width: 180px;
  }
  button.long {
    width: 320px;
  }
  button.extra-large {
    width: 400px;
    justify-content: flex-start;
  }
`;

export const BodyRow = styled.button`
  display: flex;
  align-items: center;

  height: 73px;

  border: none;
  border-bottom: 1px solid ${colors.border_200};
  background-color: ${colors.background_primary};
  outline: none;

  div.short {
    width: 122px;
    justify-content: center;
  }

  div.short-medium {
    width: 160px;
    justify-content: center;
  }
  div.medium {
    width: 180px;
    justify-content: center;
  }
  div.long {
    width: 320px;
    justify-content: flex-start;
  }
  div.extra-large {
    width: 400px;
    justify-content: flex-start;
  }
`;

export const TableHeadColumn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  padding: 0 16px;

  outline: none;
  background: none;
  border: none;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.title};
    font-size: 0.875rem;
    font-family: ${fonts.primary};

    font-weight: 600;

    svg {
      margin-left: 15px;
      color: ${colors.title};
    }
  }
`;

export const TableBodyColumn = styled.div`
  display: flex;
  align-items: center;

  padding: 0 25px;

  p {
    font-size: 0.875rem;
    font-family: ${fonts.primary};
    font-weight: 600;
    color: ${colors.text_detail};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  strong {
    font-size: 0.8125rem;
    font-family: ${fonts.secondary};
    font-weight: 600;
    color: ${colors.text_detail};
  }
`;
