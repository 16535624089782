import styled from "styled-components";
import { colors, fonts } from "../../../../styles/theme";

export const Section = styled.div`
  width: 20%;
  min-width: 250px;
  max-width: 300px;

  margin-right: 30px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 3px;
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled.p`
  font-size: 0.875rem;
  font-family: ${fonts.primary};

  color: ${colors.title};
`;

interface Props {
  empty: boolean;
}

export const ClearBox = styled.button<Props>`
  margin-left: 10px;

  height: 1.25rem;
  width: 1.25rem;

  border-radius: 3px;

  border: 1px solid ${colors.border_200};
  background-color: ${({ empty }) =>
    empty ? colors.main : colors.background_primary};

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.background_primary};
`;
