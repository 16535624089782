import styled from "styled-components";
import { colors, fonts } from "../../../../../styles/theme";

interface Props {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 45px;

  display: flex;

  border-bottom: 1px solid ${colors.border_400};

  align-items: flex-end;
`;

export const MenuBorder = styled.div`
  display: flex;

  margin-right: 10px;
`;

export const MenuButton = styled.button<Props>`
  display: flex;

  align-items: center;
  padding: 8px 35px;

  outline: none;
  border: 1px solid ${colors.border_400};
  border-bottom: none;

  background-color: ${({ isSelected }) =>
    isSelected ? colors.border_400 : colors.background_third};

  font-family: ${fonts.primary};

  font-size: 0.875rem;
  color: ${({ isSelected }) =>
    isSelected ? colors.background_primary : colors.text_detail};
`;
