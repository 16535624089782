import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 20px;
  margin-bottom: auto;
`;
