import styled from "styled-components";
import { colors, fonts } from "../../../../styles/theme";

export const Section = styled.div`
  width: 20%;
  min-width: 250px;
  max-width: 300px;

  margin-right: 30px;
`;

export const SectionTitle = styled.p`
  margin-bottom: 3px;
  font-size: 0.875rem;
  font-family: ${fonts.primary};

  color: ${colors.title};
`;
