import { ChangeEvent, HTMLAttributes } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import api from "../../../../services/api";

import { Section, Label, Image, InputContainer, Input } from "./styles";

interface Props extends HTMLAttributes<HTMLInputElement> {
  title: string;
  name: string;
  control: Control;
  unit?: string;
  sectionStyle?: object;
  csid: string;
  mpid: string;
  cpno?: string;
  docid: string;
  up: string;
  setValue?: UseFormSetValue<FieldValues>;
}

export function ImageSection({
  name,
  control,
  title,
  unit,
  sectionStyle = {},
  csid,
  mpid,
  cpno,
  docid,
  up,
  setValue,
  ...rest
}: Props) {
  return (
    <Section style={sectionStyle}>
      <Label>{title}</Label>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          async function handleChange(e: ChangeEvent<HTMLInputElement>) {
            try {
              const file = e.target.files;
              if (!file) {
                throw new Error("ファイルを正しく選択してください");
              }

              const data = new FormData();
              data.append("ukey", "trial");
              data.append("fid", "19");
              data.append("mpid", String(mpid));
              data.append("csid", String(csid));
              data.append("cpno", String(cpno));
              data.append("docid", String(docid));
              data.append(`up_${up}`, file[0]);

              const response = await api.post("/", data);
              console.log(response.data.data);
              if (setValue) {
                setValue(name, response.data.data.photo_document);
              }
              return;
            } catch (error) {
              console.log(error);
            }
          }

          return (
            <InputContainer>
              <label htmlFor={name}>
                <Input
                  type="file"
                  id={name}
                  name={`up_${up}`}
                  onChange={(e) => {
                    handleChange(e);
                    onChange(e);
                  }}
                  {...rest}
                />
                {value && <Image src={value} alt={title} />}
              </label>
            </InputContainer>
          );
        }}
        name={name}
      />
    </Section>
  );
}
