import { TextareaHTMLAttributes } from "react";
import { Control, Controller } from "react-hook-form";

import { Section, Label, InputContainer, Input, Unit } from "./styles";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  title: string;
  name: string;
  control: Control;
  unit?: string;
  sectionStyle?: object;
}

export function TextareaSection({
  name,
  control,
  title,
  unit,
  sectionStyle = {},
  ...rest
}: Props) {
  return (
    <Section style={sectionStyle}>
      <Label htmlFor={name}>{title}</Label>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputContainer>
            <Input id={name} onChange={onChange} value={value} {...rest} />
            {unit && <Unit>{unit}</Unit>}
          </InputContainer>
        )}
        name={name}
      />
    </Section>
  );
}
